import React, { useState } from "react";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "react-bootstrap";
import logo from '../../assets/img/2x/logo@2x.png'

export default function TicketNavbar({...props}) {
  const auth = props.auth
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseOut, setCollapseOut] = useState("");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  const Mynav = () =>{
    if(auth.login){
      return (
        <Nav className="float-right">
          <NavItem>
            <NavLink href="/">Eventos</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/cuenta">Tú cuenta</NavLink>
          </NavItem>
         {auth?.user?.productor === true ? <NavItem>
            <Button
              className="nav-link d-none d-lg-block text-white font-weight-bold"
              color="primary"
              href="/cuenta/crear-evento"
            >
            Crear evento
            </Button>
          </NavItem> : null}
        </Nav>
      )
    }
    return (
      <Nav className="float-right">
          <NavItem>
            <NavLink href="/">Eventos</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/login">Iniciar sesión</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/registro">Registrarse</NavLink>
          </NavItem>
          <NavItem>
            <Button
              className="nav-link d-none d-lg-block text-white font-weight-bold"
              color="primary"
              href="/registro-productor"
            >
            ¿Eres productor?
            </Button>
          </NavItem>
      </Nav>
      )
  }
  const ResponsiveNav = () =>{
    if(auth.login){
        return (
          <Nav className="p-5">
                {auth?.user?.productor === true ?
                <NavItem>
                  <NavLink href="/cuenta/crear-evento"><strong className="text-primary">Crear evento</strong></NavLink>
                </NavItem> 
                : null}
                <NavItem>
                  <NavLink href="/">Eventos</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/cuenta">Tú cuenta</NavLink>
                </NavItem> 
            </Nav>
        )
    }else{
      return(
        <Nav className="p-5">
          <NavItem>
            <NavLink href="/">Eventos</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/login">Iniciar Sesión</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/registro">Registrarse</NavLink>
          </NavItem>
          <Nav.Item>
            <Nav.Link href="/registro-productor"><strong className="text-primary">Registro de productores</strong></Nav.Link>
          </Nav.Item>
        </Nav>
      )
    }
  }
 

  return (
    <Navbar bg="white" expand="sm">
      <Container>
        <div className="row align-items-center justify-content-between w-100 no-gutters">
          <NavbarBrand>
            <a href="/">
              <img height='35' src={logo} alt="logo" className=" d-block" href='/' />
            </a>
          </NavbarBrand>
          <div className=" col-6 d-none d-md-block">
            <Mynav />
          </div>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className={"mobilesize " + collapseOut} in={collapseOpen} onExiting={onCollapseExiting} onExited={onCollapseExited}>
          <div className={"justify-content-end " + collapseOut}>
            <h2 className="text-primary px-5 mb-0 pt-5">Menu Principal</h2>
            <ResponsiveNav />
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
}
