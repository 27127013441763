import React from "react";
import ReactDom from "react-dom";
import App from "./app";
import { Provider } from 'react-redux';
import store, {history} from './redux/store'

ReactDom.render(
    <Provider store={store}>
       <App history={history} /> 
    </Provider>
, document.querySelector("#root"));