import AddEventForm from 'components/forms/AddEventForm'
import React from 'react'
import { Container } from 'react-bootstrap'

const AddEvento = () => {
  return (
    <Container>
        <AddEventForm />
    </Container>
  )
}

export default AddEvento