import React from 'react'

const Error404 = () => {
  return (
    <div className='container text-center mt-5'>
        <h1 className='display-1' style={{fontSize: '200px'}}>404</h1>
        <h3>ups, el contenido no se encuentra</h3>
    </div>
  )
}

export default Error404