import api  from '../../components/api/index'
import { push } from 'connected-react-router'
// import Axios from 'axios';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

export const AUTH_CHECKUSER_REQUEST = 'AUTH_CHECKUSER_REQUEST';
export const AUTH_CHECKUSER_SUCCESS = 'AUTH_CHECKUSER_SUCCESS';
export const AUTH_CHECKUSER_FAIL = 'AUTH_CHECKUSER_FAIL';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';


export const act_login_request = (mensaje) => {
    return {
        type: AUTH_LOGIN_REQUEST,
        payload: mensaje
    }
}

export const act_login_success = (mensaje) => {
    return {
        type: AUTH_LOGIN_SUCCESS,
        payload: mensaje
    }
}
export const act_login_fail = (mensaje) => {
    return {
        type: AUTH_LOGIN_FAIL,
        payload: mensaje
    }
}

export const act_checkuser_request = (mensaje) => {
    return {
        type: AUTH_CHECKUSER_REQUEST,
        payload: mensaje
    }
}
export const act_checkuser_success = (mensaje) => {
    return {
        type: AUTH_CHECKUSER_SUCCESS,
        payload: mensaje
    }
}
export const act_checkuser_fail = (mensaje) => {
    return {
        type: AUTH_CHECKUSER_FAIL,
        payload: mensaje
    }
}

export const act_logout = (mensaje) => {
    return {
        type: AUTH_LOGOUT,
        payload: mensaje
    }
}
const userlogin = (res) => {
    return async (dispatch) => {
        dispatch(act_login_request())
        await api.post('auth/local', {'identifier': res.email, 'password': res.password}, { withCredentials: true })
        .then(response =>{
            dispatch(act_login_success(response.data.user))
            dispatch(push('/wiewieiwei'))
            window.location.replace('/')
        }).catch(response_fail => {
            // console.log('response_fail', response_fail)
            dispatch(act_login_fail(response_fail.message))
            
        })
    }
}
const checkuser = () => {
    return async (dispatch) => {
        dispatch(act_checkuser_request())
            await api.get('users/me', { withCredentials: true }).then(res => {
                dispatch(act_checkuser_success(res.data))
            }).catch(res => {
                dispatch(act_checkuser_fail())
            })
        
    }
}
const userLogout = () => {
    return async (dispatch) =>{
        dispatch(act_logout())
        dispatch(push('/login'))
        window.location.reload(false);
    }
}


export { checkuser, userlogin, userLogout}