import { getEvento } from 'components/api/service';
import Eventotickets from 'components/events/eventotickets';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Error404 from './404';
import moment from 'moment';
import { IoCalendar, IoChevronDown, IoChevronUp, IoHappy, IoLogoFacebook, IoLogoTwitter, IoTrailSign, IoWarning } from 'react-icons/io5';

const Evento = () => {
    const { id }= useParams()
    const [contenido, setContenido] = useState(false);
    const [boxProductor, setBoxProductor] = useState(true)

    useEffect(() => {
        let ignore = false
        if(!ignore){
            const tomarDatos = async() =>{
                let data = await getEvento(id)
                setContenido(data)
            }
            tomarDatos()
        }
        return ()=>{ignore = true}
    }, [id]);

    
    if(contenido.id){  
        return (
            <Container className='my-5'>
                <Row>
                    <Col lg='6' className='p-0 rounded-left'>{
                        contenido?.imagen !== null ? 
                        <img className='rounded-0 fit-img' width='100%' height='100%' src={process.env.REACT_APP_API+contenido?.imagen?.url} alt={contenido.titulo} />
                        :
                        <img src={require("../assets/img/no-image.jpg").default} alt="no imagen" />
                    }
                    </Col>
                    <Col lg='6' className='bg-white rounded-right  p-4 pl-5'>
                        <span className=' font-weight-bold text-gray cap'>Evento</span>
                        <h1 className=' font-weight-bold display-3 cap mb-0'>{contenido.titulo}</h1>
                        {/* <hr /> */}
                            <div className='px-4 py-3 my-2 row'>
                                <div className='col-3 align-items-center d-flex'><IoCalendar className='text-gray' size={50} /></div>
                                <div className='col-9 pl-3'>
                                    <small className='text-gray'>Fecha</small>
                                    <h4 className='cap mb-0 font-weight-bold'>{moment(contenido.inicio).format('dddd DD MMMM YYYY HH:mm')} hrs</h4>
                                    {/* <h4 className='cap mb-0'>{contenido.region} - {contenido.ciudad}</h4> */}
                                </div>
                            </div>
                            <div className='px-4 py-3 my-2 row'>
                                <div className='col-3 align-items-center d-flex'><IoTrailSign className='text-gray' size={50} /></div>
                                <div className='col-9 pl-3'>
                                    <small className='text-gray'>Ubicacion</small>
                                    <h4 className='cap mb-0 font-weight-bold'>{contenido.ubicacion}</h4>
                                    <h4 className='cap mb-0'>{contenido.region} - {contenido.ciudad}</h4>
                                </div>
                            </div>
                            <div className={`px-4 py-3 my-3 font-weight-bold mb-0 rounded border text-center`}>
                                {contenido.mayor_edad ? <div className='h3 text-yellow mb-0'><IoWarning className='mr-3' size={40} />Evento para mayores de 18 años</div> : <div className='h3 text-green mb-0'><IoHappy className='mr-3' size={40} /> Para todo publico</div>}
                            </div>
                        <span className='text-gray mb-2 d-block'>Organizador del Evento</span>
                        <div className='bg-gray rounded p-2 position-relative overflow-hidden mb-3' style={ boxProductor ? {height: '50px'}: {height: 'auto'} }>
                                <div className='row'  onClick={()=> setBoxProductor(!boxProductor) }>
                                    <h3 className='col cap font-weight-bold h4'>{contenido.author.empresa}</h3>
                                    <span className='text-primary mr-3'> {boxProductor ? <IoChevronDown size={30} /> : <IoChevronUp  size={30}/>}</span>
                                </div>
                                <a className='btn btn-info w-100' href={`mailto:${contenido.author.email_publico}`}>Contactar por Email</a>
                                {/* <a className='btn btn-info w-100' href={`tel:${contenido.author.telefono_publico}`}>Llamar {contenido.author.telefono_publico}</a> */}
                                {/* <h4>{contenido.author.rut_empresa}</h4>
                                <h4>{contenido.author.giro}</h4>
                                <h4>{contenido.author.direccion}</h4>
                                <h4>{contenido.author.email_publico}</h4>
                                <h4>{contenido.author.telefono_publico}</h4> */}
                        </div>
                        <div className='d-flex justify-content-center'>
                            <a className='btn btn-facebook mr-2' href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_APP + '/evento/' + contenido.slug}`} target='_blank' rel="noreferrer" ><IoLogoFacebook size={20} className='mr-3' /> Compartir</a>
                            <a className='btn btn-twitter' href={`http://www.twitter.com/share?url=${process.env.REACT_APP_APP + '/evento/' + contenido.slug}`} target='_blank' rel="noreferrer" ><IoLogoTwitter size={20} className='mr-3' /> Compartir</a>
                        </div>
                    </Col>
                    <Col md={12} className='mt-3'>
                        <h2 className=' font-weight-bold text-gray mt-3 text-center text-md-left col-12'>Información de entradas</h2>
                        <Eventotickets props={contenido} />
                    </Col>
                    <h2 className='font-weight-bold text-gray mt-3 text-center text-md-left col-12'>Información</h2>
                    <Col md={12} className='bg-white rounded p-5'>
                        <div>{contenido.contenido}</div><br />
                        <small className='text-gray'>Los comentarios y/o textos ingresados son de exclusiva responsabilidad del Productor y/o Organizador del Evento</small>
                    </Col>
                </Row>
            </Container>
            );
    }
    if(contenido.length === 0){
        return (
            <Error404 />
        )
    }
    
    return (
        <Container className=' d-flex h-100'>
            <div className='d-flex align-self-center mx-auto h-100'>
                <Spinner animation="border" role="status" size="lg" variant="primary" className="" />
            </div>
        </Container>
    )
};

export default Evento;
