import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ConnectedRouter } from "connected-react-router";


import ReactGA from "react-ga4";


// import "assets/css/nucleo-icons.css";
import "assets/css/app.min.css";
// import "assets/scss/app.scss"
// import "assets/demo/demo.css";

// import { useSelector, useDispatch } from "react-redux";

import Home from 'pages/home/index'
import Registro from 'pages/registro'
import Terminos from 'pages/terminos'
import Evento from "pages/evento";
import registroCompra from "pages/comprar";
import  RegistroCompraConUsuarioRegis from 'pages/comprarUr'
import FinishCompra from "pages/finish";
import Login from "pages/login";
import TicketNavbar from "components/Navbars/simpleNavbar";
import Footer from "components/Footer/Footer";
import { checkuser } from "redux/actions/AuthActions";
import { Cargando } from "components/general/cargando";
import LostAccount from "pages/lostAccount";
import Active from "pages/active";
import error404 from "pages/404";
import Acount from "pages/acount";
import AddEvento from "pages/AddEvento";
// import MyEvents from "pages/myEvents";
import MyEventsCompra from "pages/myEventsCompra";
import Profile from "pages/profile";
import MyEventsVentas from "pages/myEventsVentas";
import EditEvento from "pages/EditEvento";
import Compras from "pages/Compras";
import RegistroProductor from "pages/registroProductor";
import Pagina from "pages/pagina";
import ScrollToTop from "components/general/scrollup";
import MyEventsTickets from "pages/myeventsTickets";
import Registrado  from "pages/registrado";
import EventoPrivado from "pages/eventoPrivado";
import Productor from "pages/productor"
import { VentaDetalle } from "pages/productor/detalle";
import { Cortesia } from "pages/productor/cortesia";

// const ga4react =  new GA4React("G-11WBR6MX0H");

const App = ({ history }) =>{
    ReactGA.initialize("G-3CY59V1RTC");
    const authorize = useSelector((state) => state.isAuth);
    // console.log('authorize', authorize)
    const dispatch = useDispatch();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: history.location.pathname });
        dispatch(checkuser());
        // eslint-disable-next-line
      }, [dispatch]);

    if(!authorize.loading){ 
        return <Cargando />
    }

    return (
        <ConnectedRouter history={history}>
        <BrowserRouter>
            <TicketNavbar auth={authorize} />
            <div className="contenido">
            <ScrollToTop />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/registro" component={!authorize.login ? Registro : Registrado} />
                    <Route exact path="/registro-productor" component={RegistroProductor} />
                    <Route exact path="/termino-y-condiciones" component={Terminos} />
                    <Route exact path="/evento/:id" component={Evento} />
                    <Route exact path="/privado/:id" component={EventoPrivado} />
                    <Route exact path="/informacion/:id" component={Pagina} />
                    <Route exact path="/comprar" component={authorize.login ? ()=><RegistroCompraConUsuarioRegis auth={authorize} /> : registroCompra} />
                    <Route exact path="/finish" component={FinishCompra} />
                    <Route exact path="/login" component={authorize.login ? ()=><Acount auth={authorize} /> : Login} />
                    <Route exact path="/cuenta" component={authorize.login ? ()=><Acount auth={authorize} /> : Login}/>
                    <Route exact path="/cuenta/crear-evento" component={authorize.login ? ()=><AddEvento auth={authorize} /> : Login}/>
                    <Route exact path="/cuenta/editar-evento" component={authorize.login ? ()=><EditEvento auth={authorize} /> : Login}/>
                    {/* <Route exact path="/cuenta/eventos" component={authorize.login ? ()=><MyEvents auth={authorize} /> : Login}/> */}
                    <Route exact path="/cuenta/productor" component={authorize.login ? ()=><Productor /> : Login}/>
                    <Route exact path="/cuenta/venta-detalle" component={authorize.login ? ()=><VentaDetalle /> : Login}/>
                    <Route exact path="/cuenta/cortesia" component={authorize.login ? ()=><Cortesia/> : Login}/>
                    <Route exact path="/cuenta/eventos/ventas" component={authorize.login ? ()=><MyEventsVentas auth={authorize} /> : Login}/>
                    <Route exact path="/cuenta/evento/compras" component={authorize.login ? ()=><MyEventsCompra auth={authorize} /> : Login}/>
                    <Route exact path="/cuenta/evento/tickets" component={authorize.login ? ()=><MyEventsTickets auth={authorize} /> : Login}/>
                    <Route exact path="/cuenta/perfil" component={authorize.login ? ()=><Profile auth={authorize} /> : Login}/>
                    <Route exact path="/cuenta/compras" component={authorize.login ? ()=><Compras auth={authorize} /> : Login}/>
                    <Route exact path="/recuperar-cuenta" component={LostAccount} />
                    <Route path="/recuperar-cuenta/:lost_token" component={LostAccount} />
                    <Route exact path="/activar-cuenta" component={Active} />
                    <Route path="/activar-cuenta/:active_token" component={Active} />
                    <Route path="*" component={error404} />
                </Switch>
            </div>
            <Footer />
        </BrowserRouter>
        </ConnectedRouter>
    )
}

export default App