import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoTicketSharp } from 'react-icons/io5'

export const CurrentTickets = ({props, bg}) => {
  return (
        <Col>
            <Row className=' justify-content-center'>
            {props.map((ticket, index) => {
                return (
                    <Col md={3} sm='auto' lg={3} key={index}>
                        <Col className='py-4 text-center mb-3 rounded' style={{background: bg}}>
                            <IoTicketSharp size={50} className='d-block mx-auto' />
                            <span className='font-weight-bold d-block text-center'>{ticket.nombre}</span>
                            <span className='d-block text-center'>disponibles: {ticket.cantidad}</span>
                            <span className='d-block text-center'>${ticket.valor}</span>
                        </Col>
                    </Col>
                )
            })}
            </Row>
        </Col>
  )
}
