import { useQuery } from '@tanstack/react-query'
import { getProductorTotalTickets } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
// import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoCheckmarkCircle, IoWarning } from 'react-icons/io5'

export const Tickets = ({props}) => {
    console.log('props', props.tickets)
    const { isLoading, isError, data, error } = useQuery(['todos'], () => getProductorTotalTickets(props.id), {retry: false, refetchOnWindowFocus: false})
    console.log('data', data)
    // const [datos, setDatos] = useState([])
    // useEffect(() => {
    //     let ignore = false
    //     if(!ignore){
    //       const tomardatos =async()=>{
    //         let datos = await getProductorTotalTickets(props.id)
    //         setDatos(datos)
    //       }
    //       tomardatos()
    //     }
    //     return ()=>{ignore = true}
    //   }, [props.id])
      const punto = (input) => {
        if(input === 0 || input === null || input === undefined){
          return 0
        }
          return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      
      if (isLoading) {
        return <CargandoBox />
      }
    
      if (isError) {
        return <span>{error.message}</span>
      }

    // if empty data
    if (!data.length) {
        return (
            <Col className='text-center'>
                <span className='bg-gray p-3 rounded'>No hay tickets</span>
            </Col>
        )
    }
  return (
    <Col>
        <Col className='bg-info mb-1 rounded py-3 text-white text-center font-weight-bold'>
            <Row >
                <Col xs={1} md={1}><span>activo</span></Col>
                <Col><span>disponible</span></Col>
                <Col><span>Nombre</span></Col>
                <Col><span>Valor</span></Col>
                <Col><span >Comprados</span></Col>
                <Col><span>Total</span></Col>
            </Row>
        </Col>
        {data.map((res, index) => {
                        let data = props.tickets.map((yoyoh) => 
                        res.nombre === yoyoh.nombre & res.valor === yoyoh.valor ? yoyoh.cantidad : false)          
                        .filter(x => x)
                        return(
                        <Col className='bg-white mb-1 rounded py-3' key={index}>
                            <Row >
                                <Col xs={1} md={1} className='text-center'>
                                {data > 0 ? <IoCheckmarkCircle size={25} className='text-info' /> : <IoWarning size={25} className='text-warning' />}
                                </Col>
                                <Col>
                                    <span className='d-block h5 mb-0 text-center font-weight-bold'>{data >0 ? data : 0}</span>
                                </Col>
                                <Col>
                                    <span className='d-block h5 mb-0 text-center font-weight-bold'>{res.nombre}</span>
                                </Col>
                                <Col>
                                    <span className='d-block h5 mb-0 text-center font-weight-bold'>${punto(res.valor)}</span>
                                </Col>
                                <Col>
                                    <span className='d-block h5 mb-0 text-center font-weight-bold'>{res.cantidad}</span>
                                </Col>
                                <Col>
                                    <span className='d-block h5 mb-0 text-center font-weight-bold'>${punto(res.totales)}</span>
                                </Col>
                            </Row>
                        </Col>
                        )
                    }
        )}
    </Col>
  )
}
