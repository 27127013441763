import { getPagina } from 'components/api/service'
import React, { useEffect, useState } from 'react'
import { Col, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Error404 from './404'
import { CargandoBox } from 'components/general/cargandoBox'

const Pagina = () => {
    const {id} = useParams()
    const [contenido, setContenido] = useState([])
    // console.log('contenido', contenido.length)
    // console.log('contenido', contenido)

    useEffect(() => {
        let ignore = false
        if(!ignore){
            const tomarDatos = async() =>{
                let data = await getPagina(id)
                setContenido(data)
            }
            tomarDatos()
        }
        return ()=>{ignore = true}
    }, [id]);

    
    if(contenido.id){
        return (
            <Container>
                    <h2><strong>{contenido.nombre}</strong></h2>
                <Col md='12' className='rounded bg-white p-5'>
                    {/* {markdown.toHTML(contenido.contenido)} */}
                    <ReactMarkdown children={contenido.contenido} />
                    {/* {contenido.contenido} */}
                </Col>
            </Container>
        )
    }
    if(contenido.length === 0){
        return <Error404 />
    }
    return (
        <CargandoBox />
      )
}

export default Pagina