import RootReducer from './reducers/RootReducers'
import { createStore, applyMiddleware } from 'redux'

//connect-react-redux
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

// remover composeWithDevTools si pasa a produccion
// import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
// import {logger} from 'redux-logger'
// import { createLogger } from 'redux-logger'

export const history = createBrowserHistory()

// const logger = createLogger({
//     collapsed: true
//   });

// dev mode
// const store = createStore(RootReducer(history), composeWithDevTools(
//     applyMiddleware(routerMiddleware(history), logger, thunk)
// ))

//production mode
const store = createStore(RootReducer(history),
    applyMiddleware(routerMiddleware(history), thunk)
)


export default store