import React from 'react'
import { Col } from 'react-bootstrap'

export const TituloExpand = ({titulo}) => {
    
  return (
    <Col md={12}>
            <h2 className='h4 py-1 mb-0 px-0 border-bottom mb-2'>{titulo}</h2>
        </Col>
  )
}
