import React, { useState } from 'react'
import { Row, Col, Alert, InputGroup, Button, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { editUser } from 'components/api/service';
import { lostPass } from 'components/api/service';

const PerfilUser = ({...props}) => {
    const user = props.user
    const [mostrarerror, setMostrarerror] = useState(null)
    const [completado, setCompletado] = useState(false)
    const [envioPass, setEnvioPass] = useState(null)
    const [envioPassLoad, setEnvioPassLoad] = useState(false)

    const cambioPass = async() =>{
        setEnvioPassLoad(true)
        let envio = {email: user.email}
        let data
        data = await lostPass(envio)
        if(data === 400 || 'error'){
            setEnvioPass(false)
            setEnvioPassLoad(false)
        }
        if(data.ok === true){
            setEnvioPass(true)
            setEnvioPassLoad(false)
        }
    }

      //validador de telefono
    const phoneValidador = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const schema = Yup.object({
        nombres: Yup.string()
          .min(6, 'Debe tener 6 caracteres minimos')
          .required('Requerido'),
        apellidos: Yup.string()
          .min(4, 'Debe tener 4 caracteres minimos')
          .required('Requerido'),
        telefono: Yup.string()
          .matches(phoneValidador, 'Solo numeros')
          .min(9, 'Debe tener 9 numeros minimos')
          .max(12, 'Debe tener 12 numeros maximos')
          .required('Requerido'),
        rut: Yup.string()
          .min(11, 'Debe tener punto y guion')
          .required('Requerido'),
      })
      if(completado){
          return(
              <Col className='bg-white rounded p-5 text-center'>
              <h2>Cambio de perfil <strong className='text-primary'>completado</strong></h2>
              </Col>
          )
      }
    return (
        <div>
        {mostrarerror && 
            <Alert variant="danger" onClose={()=>{setMostrarerror(null)}} dismissible>{mostrarerror}</Alert>
        }
        <Formik
            validationSchema={schema}
            onSubmit={ async(res, id)=>{
                await editUser(res, user.id).then(res =>{
                if(res === 1){
                setCompletado(true)
                }
                if(res === 2){
                    setMostrarerror('Revise el formulario')
                }
                });
            }}
            initialValues={{
            email: user.email ||'',
            nombres: user.nombres || '',
            apellidos: user.apellidos || '',
            rut: user.rut || '',
            telefono: user.telefono || '',
            empresa: user.empresa || '',
            giro: user.giro || '',
            email_publico: user.email_publico || '',
            description: user.description || '',
            direccion: user.direccion || '',
            rut_empresa: user.rut_empresa || '',
            telefono_publico: user.telefono_publico || '',
            }}
    >
        {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
            <Row>
                <Col lg="6" md="6">
                    <div className='bg-white rounded p-5 min-edit-productor'>
                        <h3 className='mb-3'>Informacion Personal aaa</h3>
                        <p className='mb-4 font-weight-bold text-info'>Esta Información es privada</p>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationFormik01">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    disabled
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik02">
                                <Form.Label>Nombres</Form.Label>
                                <Form.Control
                                type="text"
                                name="nombres"
                                value={values.nombres}
                                onChange={handleChange}
                                isInvalid={!!errors.nombres}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.nombres}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik03">
                                <Form.Label>Apellidos</Form.Label>
                                <Form.Control
                                type="text"
                                name="apellidos"
                                value={values.apellidos}
                                onChange={handleChange}
                                isInvalid={!!errors.apellidos}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.apellidos}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="5" controlId="validationFormik04">
                                <Form.Label>Rut</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="rut"
                                    name="rut"
                                    value={values.rut}
                                    onChange={handleChange}
                                    isInvalid={!!errors.rut}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.rut}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="7" controlId="validationFormik05">
                                <Form.Label>Telefono</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="telefono"
                                    name="telefono"
                                    value={values.telefono}
                                    onChange={handleChange}
                                    isInvalid={!!errors.telefono}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.telefono}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Col md='12'>
                            <h3 className='my-3'>Cambio de contraseña</h3>
                            <p>Para el cambio de contraseña se verificara a traves de su correo electronico</p>
                            <Button variant={envioPass ? 'success': 'info'} size='sm' onClick={cambioPass} disabled={envioPassLoad || envioPass === true } className='bg-info'>
                                {envioPass === null ?
                                <div>
                                    Solicitar cambio de contraseña &nbsp;&nbsp;
                                    {envioPassLoad && <Spinner animation="border" role="status" size='sm' />}
                                </div>:
                                <div>{envioPass  ? 'Te enviamos un correo para el cambio de contraseña' : 'hubo un error en el envio'}</div>
                                }
                                </Button>
                            </Col>
                            <Col md={12} className='mt-5 d-flex justify-content-end'>
                            <Button size='lg' type="submit" >
                                <strong>Actualizar Informacion de Perfil</strong>
                            </Button>
                            </Col>
                        </Form.Row>
                    </div>
                </Col>
            </Row>
        </Form>
        )}
        </Formik>
        </div>
    )
}

export default PerfilUser