import React, { useState } from 'react'
import { Col, Row, InputGroup, Container, Button, Spinner} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { lostPass } from 'components/api/service';
import { resetPass } from 'components/api/service';


const LostAccountForm = ({match}) => {
  const [enviado, setEnviado] = useState(null)
  const [enviadoReset, setEnviadoReset] = useState(null)
  const [cargando, setCargando] = useState(false)
  const [cargandoReset, setCargandoReset] = useState(false)

  // console.log('envioPass', enviadoReset)

  const schema = Yup.object({
    email: Yup.string().email('Correo de email invalido').required('Requerido'),
  })
  const schemaReset = Yup.object({
    password: Yup.string().min(6, 'Debe tener 6 caracteres minimos').required('Requerido'),
    passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Contraseña debe ser igual').required('Requerido'),
  })
  if(enviado){
    return(
      <>
      <Container>
        <Col md={12} className='bg-white rounded p-5 text-center mt-5'>
          <h2>Te enviamos un correo para que recuperes tu cuenta</h2>
          <h3>Revisa tu correo, te enviamos un link para recuperar tu cuenta</h3>
        </Col>
      </Container>
      </>
    )
  }
  if(enviadoReset === 400){
    return(
      <div className='container bg-white rounded p-5 text-center mt-5'>
      <h2>La solicitud de recuperar de cuenta a caducado o es erronea</h2>
      <h4>Para una nueva solicitud de recuperar cuenta <a href="/recuperar-cuenta">click aqui</a></h4>
      </div>
  )
  }
  if(enviadoReset === 200){
    return(
      <div className='container bg-white rounded p-5 text-center mt-5'>
      <h2>Has cambiado con exito tu contraseña</h2>
      <h4>Para ingresar con tu nueva cuenta <a href="/login">click aqui</a></h4>
      </div>
  )
  }
  if(match !== undefined){
    return (
      <Container className='container mt-5'>
        <Row className=' d-flex justify-content-center'>
          <div className='col-6'>
          <Col className=''>
            <h2 className='font-weight-bold'>Cambio de contraseña</h2>
          </Col>
          <Col className='bg-white p-5 rounded'>
            <Formik
                    validationSchema={schemaReset}
                    onSubmit={ async(values) =>{
                      setCargandoReset(true)
                      values.code = match
                      let envioPass = await resetPass(values)                     
                      setEnviadoReset(envioPass)
                    }
                  }
                  initialValues={{
                    password: '',
                    passwordConfirm: '',
                  }}
                  >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="validationFormik01">
                        <Form.Label>Contraseña</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="password"
                            placeholder="Constraseña"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="validationFormik02">
                        <Form.Label>Repetir contraseña</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="password"
                            placeholder="Repetir Constraseña"
                            name="passwordConfirm"
                            value={values.passwordConfirm}
                            onChange={handleChange}
                            isInvalid={!!errors.passwordConfirm}
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.passwordConfirm}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Form.Row>
                    <Button type="submit" disabled={cargandoReset}>
                      {!cargandoReset ? 'Nueva contraseña' : 'Cargando'}
                      {cargandoReset && <Spinner animation="border" size="sm" role="status" className='ml-3' />}
                    </Button>
                  </Form>
                )}
            </Formik>
          </Col>
          </div>
        </Row>
      </Container>
    )
  }
  return (  
    <>
    <Container className='my-5 flex'>
      <Row className=' d-flex justify-content-center'>
        <div className='col-md-6'>
          <Col md={12} className='pl-0'>
            <h2 className="font-weight-bold">Recuperar cuenta</h2>
          </Col>
          <Col className="bg-white rounded p-5">
            <Formik
              validationSchema={schema}
              onSubmit={ (values) =>{
                  setCargando(true)
                  lostPass(values).then(res => {
                    setEnviado(res)
                  })
                }
              }
              initialValues={{
                email: '',
              }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isSubmitting,
            dirty,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormik05">
                  <Form.Label>Correo electronico</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Button type="submit" disabled={cargando}>
                {!cargando ? 'Recuperar cuenta' : 'Cargando'}
                {cargando && <Spinner animation="border" size="sm" role="status" className='ml-3' />}
              </Button>
            </Form>
          )}
            </Formik>
            <div className='row'>
              <a href="/login"  className='col-5 d-block mt-5'><small>Iniciar sesión</small></a>
              <a href="/registro" className='col-7 d-block mt-5'><small>¿Aún no tienes cuenta? Regístrate</small></a>
            </div>
          </Col>
        </div>
      </Row>
    </Container>
  </>
  )
}

export default LostAccountForm