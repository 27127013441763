import React, { useState } from 'react'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addCortesia } from 'components/api/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';


export const Add = ({props}) => {
    const idEvento = props
    const [datosForm, setDatosForm] = useState([])
    const queryClient = useQueryClient();

    const {isLoading, isSuccess, isError, mutate} = useMutation(event => addCortesia(event, idEvento), {
        onSuccess: () => {
            queryClient.invalidateQueries(['pagination'])
        }
    })
     

 
    const schema = Yup.object({
        email: Yup.string().email('Correo de email invalido').required('Requerido'),
        nombre: Yup.string()
          .min(6, 'Debe tener 6 caracteres minimos')
          .required('Requerido'),
        cantidad: Yup.number()
          .min(1, 'Debe tener minimo 1 ticket')
          .required('Requerido'),
      })
      
  return (
    <Col className='mb-2 rounded bg-white p-4'>
        {(isLoading || isError || isSuccess )&&
        <Col className=' bg-success p-2 text-black rounded mb-4 text-center'>
            {isSuccess && <strong>Enviado a {datosForm.email}</strong>}
            {isError && <strong>ocurrio un error</strong>}
            {isLoading && <strong>Enviando...</strong>}
        </Col>
        }
        <Formik
            validationSchema={schema}
            onSubmit={ async(res, { setSubmitting, resetForm })=>{
                console.log('first')
                setSubmitting(true)
                mutate(res)
                resetForm()                
                setDatosForm(res)
            }}
            initialValues={{
            email: '',
            nombre:  '',
            cantidad: '',
            }}
    >
        {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md={3}>
                        <Form.Group controlId="validationFormik01">
                            <Form.Label>Nombre del ticket</Form.Label>
                            <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="ejemplo: cancha de vip"
                                name="nombre"
                                value={values.nombre}
                                onChange={handleChange}
                                isInvalid={!!errors.nombre}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.nombre}
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group controlId="validationFormik02">
                            <Form.Label>Cantidad de tickets</Form.Label>
                            <InputGroup hasValidation>
                            <Form.Control
                                type="number"
                                placeholder="Cantidad de tickets"
                                name="cantidad"
                                value={values.cantidad}
                                onChange={handleChange}
                                isInvalid={!!errors.cantidad}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.cantidad}
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="validationFormik03">
                            <Form.Label>Email</Form.Label>
                            <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="validationFormik04">
                            {/* form gruop for buttom submit */}
                            <Form.Label>&ensp;</Form.Label>
                            <InputGroup hasValidation>
                            <Button className='m-0' type="submit" disabled={isLoading}>{isLoading ? 'enviado...' :'Enviar cortesia'}</Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                            {/* <Col md="4">
                                <Button size='lg' type="submit" >
                                    <strong>Agregar Cortesia</strong>
                                </Button>
                            </Col> */}
                </Form.Row>

            </Form>
        // <input type='text' className='form-control' placeholder='Nombre del ticket' />
        // <input type='text' className='form-control' placeholder='cantidad' />
        // <input type='text' className='form-control' placeholder='correo' />
        )}
    </Formik>
    </Col>
  )
}
