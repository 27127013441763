import IsAuthTusCompras from 'components/acount/IsAuthTusCompras'
import React from 'react'
import { Col, Container } from 'react-bootstrap'

const Compras = () => {
  return (
    <Container className='my-5'>
      <Col md={6}>
        <h2><strong>Tus Compras</strong></h2>
      </Col>
      <Col className='rounded'>
        <IsAuthTusCompras />
      </Col>
    </Container>
  )
}

export default Compras