import React, { useState } from 'react'
import { Alert, Row, Col, InputGroup, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
// import Datetime from 'react-datetime';
import { IoCloudUpload, IoTrash } from 'react-icons/io5';
import { postEvento } from 'components/api/service';
import "react-datetime/css/react-datetime.css";
import { checkEventoName } from 'components/api/service';
import { AddEventhelp } from 'components/help/addEventhelp';


const AddEventForm = () => {
    const [mostrarerror, setMostrarerror] = useState()
    const [completado, setCompletado] = useState(false)


    const addTicket = (e, values, setValues) =>{
        const tickets = [...values.tickets];
        tickets.push({ __component: "ticket.tickets", nombre: '', descripcion: '', cantidad: '', valor: '', ident: tickets.length + 1 });
        setValues({ ...values, tickets })
        // console.log('ADD TICKET values', tickets)
    }
    const removeTicket = (e, index, values, setValues) =>{
        // console.log('index', index)
        // console.log('values', values)

        const tickets = [...values.tickets]
        tickets.splice(index, 1)
        // console.log('{ ...values, tickets }', { tickets })
        // console.log('tickets', tickets)
        setValues({ ...values, tickets })
        // console.log('values', values)

    }
    const onSubmit = async (values, output) =>{
        values.slug = values.titulo.toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        let arr = {...values}
        delete arr.imagen
        delete arr.imagenDestacada
        let fulltime = (values.fecha + ' ' + values.hora).toString()
        arr.inicio = new Date(fulltime.replace(/-/g, "/"))

        const datosyimagen = new FormData()
        datosyimagen.append("data", JSON.stringify(arr))
        datosyimagen.append("files.imagen", values.imagen)
        datosyimagen.append("files.img_destacado", values.imagenDestacada)

        let send = await postEvento(datosyimagen)
        if(send === 'error'){
            setMostrarerror(true)
        }else{
            setCompletado(true)
        }
    }

    const initialValues={
        'mayor_edad': false,
        'privado': false,
        'titulo': '',
        'contenido': '',
        'fecha': '',//new Date(),
        'hora': '',//new Date(),
        // 'termino': '',//new Date(),
        'region': '',
        'ciudad': '',
        'ubicacion': '',
        'imagen': '',
        'imagenDestacada': '',
        'tickets': []
    }
    const FILE_SIZE = 1024 * 1024 * 1;
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];

    const schema = Yup.object({
        tickets: Yup.array().of(
            Yup.object().shape({
                nombre: Yup.string()
                    .required('Titulo es requerido'),
                descripcion: Yup.string()
                    .min(6, 'Debe tener 6 caracteres minimos')
                    .required('Titulo es requerido'),
                cantidad: Yup.number()
                    .integer()
                    .min(0, 'Tiene que ser igual a 0, o superior a cero')
                    .typeError("Tiene que ser un numero entero")
                    .required('Cantidad es requerido'),
                valor: Yup.number()
                .integer()
                .positive('Tiene que ser superior a cero')
                .typeError("Tiene que ser un numero entero")
                .required('Valor es requerido')
            })
        ),
        titulo: Yup.string().min(6, 'Debe tener 6 caracteres minimos').required('Requerido')
        .test('check name','el titulo ya esta en uso', async (value) => {
            if(value){
                let slug = ''
                slug = value.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
                return await checkEventoName(slug)
            }
        }),
        
        fecha: Yup.date().typeError("Fecha invalida").required('Requerido'),
        hora: Yup.string().required("requiere una hora"),
        // termino: Yup.date().typeError("Fecha invalida").required('Requerido')
        //             .min(Yup.ref('inicio'),'La fecha debe ser posterior a la fecha de inicio'),
        ciudad: Yup.string().required('Requerido'),
        region: Yup.string().required('Requerido'),
        ubicacion: Yup.string().required('Requerido'),
                
        imagen: Yup
        .mixed()
        .required("una imagen es requerida")
        .test("aspectRatio", "la imagen tiene que ser cuadrada (mismo alto y ancho)",
            value => {
                if(value){
                    return new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(value);
                        reader.onload = function(value) {
                        const img = new Image();
                        img.src = value.target.result;
                        img.onload = function() {
                            const aspectRatio = this.width / this.height;
                            resolve(aspectRatio === (1 / 1));
                        };
                        };
                    });
                }
            }
        )
        .test("fileSize", "Archivo muy pesado, maximo 1Mb", value => value && value.size <= FILE_SIZE)
        .test("fileFormat", "Solo archivos con formato de Imagenes", value => value && SUPPORTED_FORMATS.includes(value.type)
        ),

        imagenDestacada: Yup.mixed().notRequired()
        .test("aspectRatio", "la imagen tiene que tener un aspecto 40:9 (1200px*270px)",
            (value) => {
                if(value){
                    return new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(value);
                        reader.onload = function(value) {
                        const img = new Image();
                        img.src = value.target.result;
                        img.onload = function() {
                            const aspectRatio = this.width / this.height;
                            resolve(aspectRatio === (40 / 9));
                        };
                        };
                    });
                }else{ return true}
            }
        )
        .test("fileSize", "Archivo muy pesado", (value) => { if(value){return value && value.size <= FILE_SIZE}else{return true}})
        .test("fileFormat", "Solo Imagenes", (value) => {if(value){return value && SUPPORTED_FORMATS.includes(value.type)}else{return true}}
        )
    })

    if(completado){
        return(
            <Col md={12} className='bg-white rounded p-5 text-center'>
                <h2>evento agregado con <strong className='text-primary'>exitoso</strong></h2>
            </Col>
        )
    }else{
      return (
        <Row className='my-5'>
        <Col md={7}>
            <Col md={12} className='pl-0'>
            <h2 className="font-weight-bold">Crear evento</h2>
            </Col>
            <Col className="bg-white rounded p-5">
            {mostrarerror && <Alert variant="danger" onClose={()=>{setMostrarerror(false)}} dismissible>Revise el formulario</Alert>}
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({handleSubmit, handleChange, handleBlur, setFieldValue, setValues, values, touched, isSubmitting, isValid, dirty, errors,}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="formnombre">
                            <Form.Label>Titulo</Form.Label>
                            <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="Titulo de evento"
                                name="titulo"
                                value={values.titulo}
                                onChange={handleChange}
                                isInvalid={!!errors.titulo}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.titulo}
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId='formayor'>
                            <InputGroup hasValidation>
                            <Form.Check
                                type="switch"
                                id='custom-switch'
                                label='Evento para mayores de 18 años'
                                name="mayor_edad"
                                value={values.mayor_edad}
                                onChange={handleChange}
                                isInvalid={!!errors.mayor_edad}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.mayor_edad}
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId='forprivado'>
                            <InputGroup hasValidation>
                            <Form.Check
                                type="switch"
                                id='custom-switch-1'
                                label='Evento privado (solo accesible por link)'
                                name="privado"
                                value={values.privado}
                                onChange={handleChange}
                                isInvalid={!!errors.privado}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.privado}
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="formcontenido">
                        <Form.Label>Contenido</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            as="textarea"
                            placeholder="Escribe aqui el contenido..."
                            name="contenido"
                            value={values.contenido}
                            onChange={handleChange}
                            isInvalid={!!errors.contenido}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.contenido}
                        </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="forminicio">
                        <Form.Label>Fecha del evento</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            type="date"
                            name="fecha"
                            value={values.fecha}
                            // onChange={handleChange}
                            onChange={(e)=>{handleChange(e)}}
                            isInvalid={!!errors.fecha}
                        />
                        <Form.Control.Feedback type="invalid" className={errors.fecha ? 'd-block' : ''}>
                            {errors.fecha}
                        </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formhora">
                        <Form.Label>Hora del evento</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            className='col-10'
                            type="time"
                            name="hora"
                            value={values.hora}
                            onChange={(e)=>{handleChange(e)}}
                            isInvalid={!!errors.hora}
                        />
                        <Form.Control.Feedback type="invalid" className={errors.hora ? 'd-block' : ''}>
                            {errors.hora}
                        </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formciudad">
                        <Form.Label>Ciudad</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="Ciudad"
                            name="ciudad"
                            value={values.ciudad}
                            onChange={handleChange}
                            isInvalid={!!errors.ciudad}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.ciudad}
                        </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formregion">
                        <Form.Label>Region</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="Region"
                            name="region"
                            value={values.region}
                            onChange={handleChange}
                            isInvalid={!!errors.region}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.region}
                        </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12"  controlId="formubicacion">
                        <Form.Label>Ubicacion</Form.Label>
                        <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="direccion"
                            name="ubicacion"
                            value={values.ubicacion}
                            onChange={handleChange}
                            isInvalid={!!errors.ubicacion}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.ubicacion}
                        </Form.Control.Feedback>
                        </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="formimage">
                            <Form.Label>Imagen</Form.Label>
                            <Form.Control.Feedback type="invalid" className={errors.imagen ? 'text-center d-block' : ''}>
                               <strong>{errors.imagen}</strong> 
                            </Form.Control.Feedback>
                            <div className='box-img-fomr-square d-flex align-items-center justify-content-center'>
                            { values.imagen ? <img className='form-img-square' src={URL.createObjectURL(values.imagen)} alt='upload'/> : <div className='box-img-txt'><h3>Seleccione una imagen</h3></div> }
                            <Form.Label className='btn-square-img-form'><IoCloudUpload size={40} /></Form.Label>
                            <Form.Control
                                className=' d-none'
                                type="file"
                                name="imagen"
                                onChange={(event) => {setFieldValue("imagen", event.currentTarget.files[0]) }}
                                isInvalid={!!errors.imagen}
                            />
                            </div>
                            
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="formimagedestacado">
                            <Form.Label>Imagen destacada</Form.Label>
                            <Form.Control.Feedback type="invalid" className={errors.imagenDestacada ? 'd-block text-center' : ''}>
                              <strong>{errors.imagenDestacada}</strong>  
                            </Form.Control.Feedback>
                            <div className='box-img-fomr-aspect d-flex align-items-center justify-content-center'>
                            { values.imagenDestacada ? <img className='form-img-aspect' src={URL.createObjectURL(values.imagenDestacada)} alt='upload'/> : <div className='box-img-dest-txt'><h3>Seleccione una imagen <br /> para destacar</h3></div> }
                            <Form.Label className='btn-square-img-form'><IoCloudUpload size={40} /></Form.Label>
                            <Form.Control
                                className=' d-none'
                                type="file"
                                name="imagen"
                                onChange={(event) => {setFieldValue("imagenDestacada", event.currentTarget.files[0]) }}
                                isInvalid={!!errors.imagenDestacada}
                            />
                            </div>
                            
                        </Form.Group>
                        <div className='d-flex justify-content-between  w-100'>
                            <h2 className=''>Tickets</h2>
                            <div id='ADDTICKET' className='btn addticket'  onClick={e => {addTicket(e, values, setValues); handleChange(e)}}>+</div>
                        </div>
                        {values?.tickets.map((res, index) =>{
                            const ticketErrors = (errors.tickets?.length && errors.tickets[index]) || {};
                            // eslint-disable-next-line
                            const ticketTouched = (touched.tickets?.length && touched.tickets[index]) || {};
                            return(
                            <div key={res.ident} id={index} className='border rounded col-12 my-3 p-3'>
                                <div onClick={(e) =>removeTicket(e, index, values, setValues)} className='remove-ticket-form'><IoTrash size={17} /></div>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId={`tickets.${index}.nombre`}>
                                        <Form.Label>Titulo de ticket</Form.Label>
                                        <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            placeholder="titulo de ticket"
                                            name={`tickets.${index}.nombre`}
                                            onChange={(e)=>{handleChange(e)}}
                                            isInvalid={ticketErrors.nombre}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {ticketErrors.nombre}
                                        </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>   
                                    <Form.Group as={Col} md="12" controlId={`tickets.${index}.descripcion`}>
                                        <Form.Label>Descripcion</Form.Label>
                                        <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            placeholder="Breve descripcion del ticket"
                                            name={`tickets.${index}.descripcion`}
                                            onChange={(e)=>{handleChange(e)}}
                                            isInvalid={ticketErrors.descripcion}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {ticketErrors.descripcion}
                                        </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>   
                                    <Form.Group as={Col} md="6" controlId={`tickets.${index}.valor`}>
                                        <Form.Label>Valor</Form.Label>
                                        <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            placeholder="Valor"
                                            name={`tickets.${index}.valor`}
                                            onChange={handleChange}
                                            // onChange={(e) =>{console.log('VAlues', values.tickets);console.log('eeee', e.currentTarget);handleChange(e)}}
                                            isInvalid={ticketErrors.valor}
                                            value={res.valor.toString().replace(/^0+/, '').replace(/[^0-9]/g, "")}
                                            // value={res.valor.replace(/[^0-9]/g, "")}
                                            // value={values.tickets[index].valor.replace(/[^0-9]/g, "")}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {ticketErrors.valor}
                                        </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId={`tickets.${index}.cantidad`}>
                                        <Form.Label>Cantidad</Form.Label>
                                        <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            placeholder="Cantidad"
                                            name={`tickets.${index}.cantidad`}
                                            onChange={handleChange}
                                            isInvalid={!!ticketErrors.cantidad}
                                            value={res.cantidad.toString()
                                                .replace(/[^0-9]/, '').replace(/[^0-9]/g, "")
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {ticketErrors.cantidad}
                                        </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                            </div >
                        )
                        })}
                        
                    </Form.Row>
                    <Button type="submit" disabled={!isValid || !dirty}>Crear Evento</Button>
                </Form>
            )}
            </Formik>
            </Col>
        </Col>
        <Col md={5}>
            <AddEventhelp />
        </Col>
        </Row>
      )
    }
}

export default AddEventForm