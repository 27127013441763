import React from 'react'
import { Col } from 'react-bootstrap'

const RegisterHelp = () => {
  return (
    <Col md="6" className='rounded-bottom-right-1 rounded-top-right-1 p-5 reg-bg'>
        <div className='rounded'>
            <h1 className='text-white'><strong>Registrate</strong></h1>
            <h4 className='text-white'>prepárate para vivir una nueva experiencia</h4>
        </div>
    </Col>
  )
}

export default RegisterHelp