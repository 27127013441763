import LostAccountForm from 'components/forms/lostAccountForm'
import React from 'react'
import { Container } from 'react-bootstrap'

const LostAccount = ({match}) => {
  const lost_token = match.params.lost_token
  return (
    <Container>
        <LostAccountForm match={lost_token} />
    </Container>
  )
}
export default LostAccount
