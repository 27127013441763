import { getEventosProductor } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import IsAuthVentasMap from './IsAuthVentasMap'

const IsAuthVentas = () => {
    const [lista, setlista] = useState(null)    
    useEffect(() => {
      let ignore = false
      if(!ignore){
        const tomardatos =async()=>{
          let datos = await  getEventosProductor()
            setlista(datos)
        }
        tomardatos()
      }
      return ()=>{ignore = true}
    }, [])

  if(lista === null){
    return(
      <Col md={12} className='bg-white rounded p-5' >
            <CargandoBox />
        </Col>
    )
  }
  if(lista.length === 0){
    return (
      <Col md={12} className='bg-white rounded p-5' >
        <h2>El evento no contiene registros</h2>
      </Col>
    )
  }
  if(lista.length > 0){
    return (
      <Col md={12} className='bg-white rounded p-5' >
        <h3>Detalle de ventas</h3>
        {<IsAuthVentasMap list={lista} /> }
      </Col>
    )
  }
      return(
        <Col md={12} className='bg-white rounded p-5' >
          <CargandoBox />
        </Col>
      )
    
}

export default IsAuthVentas