import LoginForm from 'components/forms/loginForm'
import React from 'react'
import { Container } from 'react-bootstrap'

const Login = () => {
  
  return (
    <Container>
        <LoginForm />
    </Container>
  )
}
export default Login
