import { activeAccount } from 'components/api/service'
import React, { useEffect, useState } from 'react'
import { Col, Row, InputGroup, Container, Button, Spinner} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { resendActive } from 'components/api/service';

const Active = ({match}) => {
    const url_token = match.params.active_token
    const [cuenta_activada, setcuenta_activada] = useState(false)
    const [cargando, setcargando] = useState(false)
    const [enviocompleto, setEnviocompleto] = useState(null)
    
    useEffect(() => {
        let ignore = false
        if(!ignore){
            if(url_token !== undefined){
                const enviodetoken = async () =>{
                    let envioDatos = await activeAccount(url_token)
                    // console.log('envioDatos', envioDatos)
                    setcuenta_activada(envioDatos)
                }
                enviodetoken()
            }
        }
        return () => { ignore = true }
        // eslint-disable-next-line
    }, [activeAccount])

    const schema = Yup.object({
        email: Yup.string().email('Correo de email invalido').required('Requerido'),
      })
    if(cuenta_activada === 200){
        return(
        <Container className='my-5'>
            <Row>
              <Col className="bg-white rounded p-5 text-center">
              <h2>Cuenta <strong className='text-primary'>activada</strong></h2>
              </Col>
            </Row>
        </Container>
        )
    }
    if(url_token === undefined){
        return (
            <>
                <Container className='my-5 flex'>
                <Row className=' d-flex justify-content-center'>
                    <div className='col-md-6'>
                    <Col md={12} className='pl-0'>
                        <h2 className="font-weight-bold">Solicitar correo de activacion</h2>
                    </Col>
                    <Col className="bg-white rounded p-5">
                    {enviocompleto === null &&
                        <Formik
                        validationSchema={schema}
                        onSubmit={ async(values) =>{
                            setcargando(true)
                        let envioForm = await resendActive(values)
                        setEnviocompleto(envioForm)
                            }
                        }
                        initialValues={{
                            email: '',
                        }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationFormik05">
                            <Form.Label>Correo electronico</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.email}
                                </Form.Control.Feedback>
                            </InputGroup>
                            </Form.Group>
                        </Form.Row>
                         <Button type="submit" disabled={cargando}>
                            {!cargando ? 'Solicitar correo de activacion' : 'Cargando'}
                            {cargando && <Spinner animation="border" size="sm" role="status" className='ml-3' />}
                        </Button>
                        </Form>
                    )}
                        </Formik>}
                    {enviocompleto === 200 && <h4 className='text-center'>Solicitud exitosa, revisa tu correo</h4>}
                    {enviocompleto === 400 && <h4 className='text-center'>Solicitud rechazada, tu cuenta ya esta activada</h4>}
                    </Col>
                    </div>
                </Row>
                </Container>
            </>
        )
    }
    return(
        <div className='container bg-white rounded p-5 text-center mt-5'>
        <h2>La solicitud de activacion a caducado o es erronea</h2>
        <h4>Puedes solicitar una nueva activacion de tu cuenta <a href="/activar-cuenta">aqui</a></h4>
        </div>
    )
}

export default Active