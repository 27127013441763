import React, { useState } from 'react'
import { postRegister } from 'components/api/service';
// import { useFormik } from 'formik'
import { Col, Row, InputGroup, Container, Button, Alert} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik';
import * as Yup from 'yup';

const RegistroForm = ({registroEnviado, setRegistroEnviado}) => {
  const [mostrarerror, setMostrarerror] = useState(null);
  // const [registroEnviado, setRegistroEnviado] = useState(false)

  //validador de telefono
  const phoneValidador = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const schema = Yup.object({
    nombres: Yup.string()
      .min(6, 'Debe tener 6 caracteres minimos')
      .required('Requerido'),
    telefono: Yup.string()
      .matches(phoneValidador, 'Solo numeros')
      .min(9, 'Debe tener 9 numeros minimos')
      .max(12, 'Debe tener 12 numeros maximos')
      .required('Requerido'),
    rut: Yup.string()
      .min(11, 'Debe tener punto y guion')
      .required('Requerido'),
    email: Yup.string().email('Correo de email invalido').required('Requerido'),
    emailConfirm: Yup.string().required('Requerido')
     .oneOf([Yup.ref('email'), null], 'email debe ser igual'),
    password: Yup.string().min(6, 'Debe tener 6 caracteres minimos').required('Requerido'),
    passwordConfirm: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Contraseña debe ser igual').required('Requerido'),
     term: Yup.bool().oneOf([true], 'tienes que aceptar los terminos y condiciones')
  })
  if(registroEnviado){
    return(
      <Container>
        <Row>
          <Col md='12' className='bg-white p-5 my-5 rounded text-center' >
            <h2 className='text-primary'>Gracias por registrarte en ticketone</h2>
            <h3>Revisa tu correo electronico para validar la cuenta</h3>
          </Col>
        </Row>
      </Container>
    )
  }
  return (  
        <Col className="bg-white rounded-bottom-left-1 rounded-top-left-1 p-5" lg="6" md="6">
        {mostrarerror && 
          <Alert variant="danger" onClose={()=>{setMostrarerror(null)}} dismissible>{mostrarerror}</Alert>
         }
        <Formik
          validationSchema={schema}
          onSubmit={ async(res)=>{
              await postRegister(res).then(res =>{
                if(res === 1){
                 setRegistroEnviado(true)
                }
                if(res === 2){
                  setMostrarerror('correo ya registrado')
                }
              });
          }}
          initialValues={{
            email: '',
            emailConfirm: '',
            nombres: '',
            apellidos: '',
            rut: '',
            telefono: '',
            password: '',
            passwordConfirm: '',
            term: ''
          }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        dirty,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>Nombres</Form.Label>
              <Form.Control
                type="text"
                name="nombres"
                value={values.nombres}
                onChange={handleChange}
                isInvalid={!!errors.nombres}
              />
              <Form.Control.Feedback type='invalid'>
              {errors.nombres}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                type="text"
                name="apellidos"
                value={values.apellidos}
                onChange={handleChange}
                isInvalid={!!errors.apellidos}
              />
              <Form.Control.Feedback type='invalid'>
              {errors.apellidos}
              </Form.Control.Feedback>
            </Form.Group>
           
            <Form.Group as={Col} md="5" controlId="validationFormik03">
              <Form.Label>Rut</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="rut"
                  name="rut"
                  value={values.rut}
                  onChange={handleChange}
                  isInvalid={!!errors.rut}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rut}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
           
            <Form.Group as={Col} md="7" controlId="validationFormik05">
              <Form.Label>Telefono</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="telefono"
                  name="telefono"
                  value={values.telefono}
                  onChange={handleChange}
                  isInvalid={!!errors.telefono}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.telefono}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Email</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Confirmar Email</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="emailConfirm"
                  value={values.emailConfirm}
                  onChange={handleChange}
                  isInvalid={!!errors.emailConfirm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.emailConfirm}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationFormik06">
              <Form.Label>Contraseña</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationFormik0">
              <Form.Label>Confirmacion de Contraseña</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  name="passwordConfirm"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  isInvalid={!!errors.passwordConfirm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirm}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Check
              name="terms"
              label="acepta los termino y condiciones"
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              id="validationFormik0"
            />
          </Form.Group>
          <Button type="submit" disabled={isSubmitting || !isValid || !dirty}>Registrarse</Button>
        </Form>
      )}
    </Formik>
        </Col>
  )

};

export default RegistroForm;
