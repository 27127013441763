import React, { useState } from 'react'
import { Row, Col, Alert, InputGroup, Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { postRegisterProductor } from 'components/api/service';

const RegProductor = () => {
    const [mostrarerror, setMostrarerror] = useState(null)
    const [completado, setCompletado] = useState(false)


    //validador de telefono
    const phoneValidador = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    
    const schema = Yup.object({
        nombres: Yup.string()
          .min(4, 'Debe tener 4 caracteres minimos')
          .required('Requerido'),
        apellidos: Yup.string()
          .min(4, 'Debe tener 4 caracteres minimos')
          .required('Requerido'),
        telefono: Yup.string()
          .matches(phoneValidador, 'Solo numeros')
          .min(9, 'Debe tener 9 numeros minimos')
          .max(12, 'Debe tener 12 numeros maximos')
          .required('Requerido'),
        empresa: Yup.string()
          .min(4, 'Debe tener 4 caracteres minimos')
          .required('Requerido'),
        giro: Yup.string()
          .min(4, 'Debe tener 4 caracteres minimos')
          .required('Requerido'),
        direccion: Yup.string()
          .min(6, 'Debe tener 6 caracteres minimos')
          .required('Requerido'),
        rut: Yup.string()
          .min(3, 'Debe tener 3 caracteres minimos')
          .required('Requerido'),
        rut_empresa: Yup.string()
          .min(6, 'Debe tener 6 caracteres minimos')
          .required('Requerido'),
        email_publico: Yup.string().email('Correo de email invalido').required('Requerido'),
        email: Yup.string().email('Correo de email invalido').required('Requerido'),
        emailConfirm: Yup.string().required('Requerido')
         .oneOf([Yup.ref('email'), null], 'email debe ser igual'),
        password: Yup.string().min(6, 'Debe tener 6 caracteres minimos').required('Requerido'),
        passwordConfirm: Yup.string()
         .oneOf([Yup.ref('password'), null], 'Contraseña debe ser igual').required('Requerido'),
      })
      if(completado){
          return(
              <Col className='bg-white rounded p-5 text-center'>
              <h2>solicitud de incorporacion de <strong className='text-primary'>nuevo productor </strong> enviada</h2>
              <h4>te responderemos tú solicitud lo antes posible</h4>
              </Col>
          )
      }
    return (
        <div>
        {mostrarerror && 
            <Alert variant="danger" onClose={()=>{setMostrarerror(null)}} dismissible>{mostrarerror}</Alert>
        }
        <Formik
            validationSchema={schema}
            onSubmit={ async(res)=>{
                let data = await postRegisterProductor(res)
                if(data === 'ok'){
                    setCompletado(true)
                }
                if(data === 400){
                    setMostrarerror('correo ya registrado')
                }
                else{
                    setMostrarerror('revise el formulario')
                }
            }}
            initialValues={{
            email: '',
            emailConfirm: '',
            nombres: '',
            apellidos: '',
            rut: '',
            telefono: '',
            empresa: '',
            giro: '',
            email_publico: '',
            description: '',
            direccion: '',
            rut_empresa: '',
            telefono_publico: '',
            password: '',
            passwordConfirm: '',
            }}
    >
        {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        dirty,
        errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
            <Row>
                <Col lg="6" md="6">
                    <div className='bg-white rounded p-5 min-edit-productor'>
                        <h3 className='mb-3'>Informacion Personal</h3>
                        <p className='mb-4 font-weight-bold text-info'>Esta Información es privada</p>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationFormik02">
                                <Form.Label>Nombres</Form.Label>
                                <Form.Control
                                type="text"
                                name="nombres"
                                value={values.nombres}
                                onChange={handleChange}
                                isInvalid={!!errors.nombres}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.nombres}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik03">
                                <Form.Label>Apellidos</Form.Label>
                                <Form.Control
                                type="text"
                                name="apellidos"
                                value={values.apellidos}
                                onChange={handleChange}
                                isInvalid={!!errors.apellidos}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.apellidos}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="5" controlId="validationFormik04">
                                <Form.Label>Rut</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="rut"
                                    name="rut"
                                    value={values.rut}
                                    onChange={handleChange}
                                    isInvalid={!!errors.rut}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.rut}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="7" controlId="validationFormik05">
                                <Form.Label>Telefono</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="telefono"
                                    name="telefono"
                                    value={values.telefono}
                                    onChange={handleChange}
                                    isInvalid={!!errors.telefono}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.telefono}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik13">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik14">
                            <Form.Label>Confirmar Email</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                type="text"
                                placeholder="Email"
                                name="emailConfirm"
                                value={values.emailConfirm}
                                onChange={handleChange}
                                isInvalid={!!errors.emailConfirm}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.emailConfirm}
                                </Form.Control.Feedback>
                            </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik15">
                            <Form.Label>Contraseña</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                type="password"
                                placeholder="Contraseña"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.password}
                                </Form.Control.Feedback>
                            </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik16">
                            <Form.Label>Confirmacion de Contraseña</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                type="password"
                                placeholder="Contraseña"
                                name="passwordConfirm"
                                value={values.passwordConfirm}
                                onChange={handleChange}
                                isInvalid={!!errors.passwordConfirm}
                                />
                                <Form.Control.Feedback type="invalid">
                                {errors.passwordConfirm}
                                </Form.Control.Feedback>
                            </InputGroup>
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Col>
                <Col lg="6" md="6">
                    <div className="bg-white rounded p-5 min-edit-productor">
                        <h3 className='mb-3'>Información Comercial</h3>
                        <p className='mb-4 font-weight-bold text-info'>Esta Información se mostratara en los eventos publicados</p>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationFormik06">
                                <Form.Label>Nombre de empresa</Form.Label>
                                <Form.Control
                                type="text"
                                name="empresa"
                                placeholder='empresa SPA'
                                value={values.empresa}
                                onChange={handleChange}
                                isInvalid={!!errors.empresa}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.empresa}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik07">
                                <Form.Label>Giro</Form.Label>
                                <Form.Control
                                placeholder='Giro de la empresa'
                                type="text"
                                name="giro"
                                value={values.giro}
                                onChange={handleChange}
                                isInvalid={!!errors.giro}
                                />
                                <Form.Control.Feedback>Listo</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="5" controlId="validationFormik08">
                                <Form.Label>Rut de empresa</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Escribe el RUT empresa"
                                    name="rut_empresa"
                                    value={values.rut_empresa}
                                    onChange={handleChange}
                                    isInvalid={!!errors.rut_empresa}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.rut_empresa}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="7" controlId="validationFormik09">
                                <Form.Label>Teléfono</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Escribe el telefono"
                                    name="telefono_publico"
                                    value={values.telefono_publico}
                                    onChange={handleChange}
                                    isInvalid={!!errors.telefono_pubico}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.telefono_pubico}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik10">
                                <Form.Label>Dirección</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Dirección"
                                    name="direccion"
                                    value={values.direccion}
                                    onChange={handleChange}
                                    isInvalid={!!errors.direccion}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.direccion}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik11">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Escribe el Email"
                                    name="email_publico"
                                    value={values.email_publico}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email_publico}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email_publico}
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationFormik12">
                                <Form.Label>Información sobre la empresa</Form.Label>
                                <Form.Control
                                as="textarea"
                                rows={4}
                                name="description"
                                placeholder='escribe un texto sobre tu Empresa'
                                value={values.description}
                                onChange={handleChange}
                                isInvalid={!!errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Col>
                <Col className='my-4 d-flex justify-content-end'>
                    <Button size='lg' type="submit" disabled={isSubmitting || !isValid || !dirty} >
                        <strong>registrarse como productor</strong>
                    </Button>
                </Col>
            </Row>
        </Form>
        )}
        </Formik>
        </div>
    )
}

export default RegProductor