import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Spinner, } from 'react-bootstrap'

import { useLocation } from 'react-router-dom';
import { postFinishCompra } from 'components/api/service';
import moment from 'moment';


const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const FinishCompra = () => {
  let query = useQuery();
  const mytoken = {"token" : query.get('token_ws')}
  const mycancel = {"cancelado" : query.get('cancel')}
  const [respuesta, setRespuesta] = useState([]);

  useEffect(() => {
    let ignore = false
    if(!ignore){
      const tomarDatos = async () =>{
        let data =  await postFinishCompra(mytoken)
        setRespuesta(data)
      }
      tomarDatos()
    }
    return () =>{ignore = true}
    // eslint-disable-next-line
  }, [postFinishCompra]);


 if(mycancel?.cancelado?.length > 0) {
    return (
      <Container className='my-5'>
      <Row>
        <Col className="bg-white rounded p-5 text-center">
        <h2 className="font-weight-bold">Transacion de compra cancelada</h2>
          <h3>cancelaste la compra</h3>
          <p>id: {mycancel?.cancelado}</p>
        </Col>
      </Row>
    </Container>
    )
  }
  if(respuesta?.response_code === 0){
    return (  
      <Container className='my-5'>
        <Row>
          <Col className="bg-white rounded p-5 text-center">
          <h2 className="font-weight-bold">Proceso de compra
            <strong className='text-primary'> Exitosa</strong>
            </h2>
            <h3>Te enviamos un correo con la informacion de compra</h3>
            <h3><strong>informacion de la compra en linea</strong></h3>
            <Row noGutters={false} className='justify-content-center' >
              <Col md={4} sm={12} className='mx-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Monto Pagado</h4>
                <p>$ {respuesta.amount}</p>
              </Col>
              <Col md={4} sm={12} className='mx-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Fecha de pago</h4>
                <p>{moment(respuesta.transaction_date).format("LLLL")}</p>
              </Col>
              <Col md={4} sm={12} className='mx-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Numero de orden</h4>
                <p>{respuesta.buy_order}</p>
              </Col>
              <Col md={4} sm={12} className='mx-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Codigo de autorizacion</h4>
                <p>{respuesta.authorization_code}</p>
              </Col>
              <Col md={4} sm={12} className='mx-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Codigo de autorizacion</h4>
                <p>{respuesta.authorization_code}</p>
              </Col>
              <Col md={4} sm={12} className='m-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Numero de tarjeta</h4>
                <p>**** **** **** {respuesta.card_detail.card_number}</p>
              </Col>
              <Col md={4} sm={12} className='mx-2 p-2 rounded mb-2 bg-light-1'>
                <h4>Medio de Pago</h4>
                <p>Webpay</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
  if(respuesta?.response_code < 0){
    return (  
      <Container className='my-5'>
        <Row>
          <Col className="bg-white rounded p-5 text-center">
          <h2 className="font-weight-bold">Proceso de compra
            <strong className='text-primary'> rechazada</strong>
            </h2>
            <h3>no se pudo completar la compra</h3>
            <h4>por motivos que el banco rechazo la transacción, por favor ponte en contacto con el emisor de tu tarjeta</h4>
          </Col>
        </Row>
      </Container>
    )
  }
  if(respuesta?.response_code === 1) {
    return (
      <Container className='my-5'>
      <Row>
        <Col className="bg-white rounded p-5 text-center">
        <h2 className="font-weight-bold">ocurrio un error</h2>
          <h3>ocurrio un error en el proceso de compra</h3>
        </Col>
      </Row>
    </Container>
    )
  }
 
  else {
    return (
      <Container className='my-5'>
      <Row>
        <Col className="bg-white rounded p-5 text-center">
        <Spinner animation="border" role="status" size="lg" variant="primary">
        </Spinner>
        </Col>
      </Row>
    </Container>
    )
  }

};

export default FinishCompra;
