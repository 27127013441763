import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Registrado = () => {
  return (
    <Container>
        <Row>
          <Col md='12' className='bg-white p-5 my-5 rounded text-center' >
            <h2 className='text-primary'>Ya estas Registrado</h2>
            <h3>Para abrir una nueva cuenta tienes que salir de esta cuenta aqui</h3>
          </Col>
        </Row>
      </Container>
  )
}

export default Registrado