import React from 'react'
import { useState} from 'react'
import { Col, Button, Row, Form } from 'react-bootstrap'
import QRCode from "qrcode.react"

export const Links = ({props}) => {
    const [copySuccess, setCopySuccess] = useState('')
    const svgRef = React.useRef()
    const copyToClipboard = (text) => {
        let copia = process.env.REACT_APP_APP + (props.privado ? '/privado/':'/evento/') +  props.slug
        navigator.clipboard.writeText(copia);
        setCopySuccess('copiado')
    }
    const download = ()=> {
        const canvas = document.querySelector('.QRcanvas > canvas');
        const link = document.createElement("a");
        link.href = canvas.toDataURL();
        link.download = `${props.titulo}-QR.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const downloadSVG = () => {
        const content = svgRef.current.children[0].innerHTML
        const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="500" width="500" viewBox="0 0 29 29">
        ${content}
        </svg>`;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([svgContent], {type: 'image/svg+xml'}));
        link.download = `${props.titulo}-QR.svg`
        link.click();
       
    }
    

  return (
    <Col sm={12} className='my-3'>
    <Row className='justify-content-center'>
        <Col xs={6} md={4} className='mb-3 mb-md-0'>
            <div className='col-12' ref={svgRef}>
                <QRCode renderAs='svg' value={process.env.REACT_APP_APP + (props.privado ? '/privado/':'/evento/') +  props.slug} />
            </div>
            <div className='QRcanvas d-none'>
                <QRCode size={500} value={process.env.REACT_APP_APP + (props.privado ? '/privado/':'/evento/') +  props.slug} />
            </div>
            {/* <QRCode value={process.env.REACT_APP_APP + (props.privado ? '/privado/':'/evento/') +  props.slug} size={120} /> */}
        </Col>
        <Col xs={5} md={5} className='my-auto text-center'>
            <Button size='sm' variant='primary' onClick={downloadSVG}><small>Descargar QR SVG</small></Button>
            <Button size='sm' variant='primary' onClick={download}><small>Descargar QR PNG</small></Button>
        </Col>
        <Col md={12} className='text-center text-md-right'>
            <Col>
        <Form.Control size="md" type="text" disabled defaultValue={process.env.REACT_APP_APP + (props.privado ? '/privado/':'/evento/') +  props.slug} className='form-control mb-2 text-center mt-md-3' />
            <Button size='sm' onClick={copyToClipboard}>
                {copySuccess === '' ? 'Copiar URL' : copySuccess}
            </Button>
            <Button variant='info' size='sm' href={process.env.REACT_APP_APP + (props.privado ? '/privado/':'/evento/') +  props.slug} target='_blank'  >
                Ver Evento
            </Button>
            </Col>
        </Col>
    </Row>
    </Col>
  )
}
