import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";

// core components
import EventosSlider from "components/events/eventosslider";
import {ListaEventos}  from "components/events/lista";
import { getEventos } from "components/api/service";

const Home = () => {
  const [listaActivos, setlistaActivos] = useState([])
  const [listaVencidos, setlistaVencidos] = useState([])
    
  useEffect(() => {
    let ignore = false
    if(!ignore){
      const tomardatos =async()=>{
        let datos = await getEventos()
        //filter and sort by date
        let filtradoActivos = datos.filter(res => new Date()  <= new Date(res.inicio)).sort((a, b) => new Date(a.inicio) - new Date(b.inicio))
        let filtradoVencidos = datos.filter(res => new Date()  >= new Date(res.inicio))
        setlistaActivos(filtradoActivos)
        setlistaVencidos(filtradoVencidos)
      }
      tomardatos()
    }
    return ()=>{ignore = true}
  }, [])

  return (
    <>
      <EventosSlider lista={listaActivos} />
      <Container>
        <Col>
          <h2 className="mt-4 font-weight-bold">Proximos Eventos</h2>
        </Col>
        <ListaEventos lista={listaActivos} />
        <Col>
          <h2 className="mt-4 font-weight-bold">Eventos pasados</h2>
        </Col>
        <ListaEventos lista={listaVencidos} />
      </Container>
      <Container className="my-5">
        <EventosSlider lista={listaActivos} />
      </Container>
    </>
  );
}
export default Home

