import React from 'react'
import { ProductorEvents } from 'components/api/service'
import { useQuery } from '@tanstack/react-query'
import { ItemEvent } from './itemEvent'
import { CargandoBox } from 'components/general/cargandoBox'
import Accordion from 'react-bootstrap/Accordion';


export const Events = () => {
    const { isLoading, isError, data, error } = useQuery(['todos'], ProductorEvents, {retry: false, refetchOnWindowFocus: false})
    
    if (isLoading) {
      return <CargandoBox />
    }
  
    if (isError) {
      return <span>{error.message}</span>
    }
    //filter data if the current date is greater than the event date
    const activos = data.filter(evento => new Date(evento.inicio) >= new Date())
    const vencidos = data.filter(evento => new Date(evento.inicio) < new Date())
    // We can assume by this point that `isSuccess === true`
    return (
      <>
        <h2 className='h3 mb-3'>Eventos activos</h2>
        <Accordion defaultActiveKey="0" className='mb-5'>
            {activos.map(evento => (
              <ItemEvent key={evento.id} evento={evento} />
              ))}
         </Accordion>
          <h2 className='h3'>Eventos pasados</h2>
        <Accordion defaultActiveKey="0" className='mb-5'>
            {vencidos.map(evento => (
              <ItemEvent key={evento.id} evento={evento} />
              ))}
         </Accordion>
      </>
    )
  
}
