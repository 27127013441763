import { 
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_FAIL,
    AUTH_LOGIN_SUCCESS,
    AUTH_CHECKUSER_REQUEST,
    AUTH_CHECKUSER_SUCCESS,
    AUTH_CHECKUSER_FAIL,
    AUTH_LOGOUT
} from '../actions/AuthActions'

const default_Auth_log = {
    // loading: true,
    login: false,
    loading: false,
    userchk: false,
    user: null,
    msj: null
}
const isAuth = (state = default_Auth_log, action) => {
    switch (action.type){
        case AUTH_LOGIN_REQUEST: 
            return {
                ...state,
                loading: true,
                user: action.payload
            }
        case AUTH_LOGIN_SUCCESS: 
            return {
                ...state,
                loading: true,
                login: true,
                user: action.payload
            }
        case AUTH_LOGIN_FAIL: 
            return {
                ...state,
                loading: true,
                login: false,
                msj: action.payload
            }
        case AUTH_CHECKUSER_REQUEST: 
            return {
                ...state,
                // loading: true,
                msj: action.payload
            }
        case AUTH_CHECKUSER_SUCCESS: 
            return {
                ...state,
                loading: true,
                login: true,
                userchk: true,
                user: action.payload
            }
        case AUTH_CHECKUSER_FAIL: 
            return {
                ...state,
                loading: true,
                login: false,
                msj: action.payload
            }
        case AUTH_LOGOUT: 
            return {
                // ...state,
                loading: true,
                login: false,
                userchk: false
            }
        default: return state;
        }
    }

export default isAuth