import RegistroForm from 'components/forms/RegistroForm';
import RegisterHelp from 'components/help/registerHelp';
import React, { useState } from 'react'
import { Row,Container } from 'react-bootstrap'

const Registro = () => {
  const [registroEnviado, setRegistroEnviado] = useState(false)
  return(
    <Container className='my-5'>
      <Row>
      {/* <Col md={12} className='pl-0'>
        <h2 className="font-weight-bold">Registrate ahora</h2>
      </Col> */}
      <RegistroForm registroEnviado={registroEnviado} setRegistroEnviado={setRegistroEnviado} />
      {!registroEnviado ? <RegisterHelp /> : ''}
      </Row>
    </Container>
  )
};

export default Registro;
