import { useQuery } from '@tanstack/react-query'
import { listaCortesia } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
import moment from 'moment'
import React, { useState } from 'react'
import { Col, Pagination } from 'react-bootstrap'

export const PaginaCortesia = ({props}) => {
    const [pagina, setPagina] = useState({id: props,comienzo: 1})
    const { isLoading, isError, data, error } = useQuery(['pagination', pagina], () => listaCortesia(queryParams), {retry: false, refetchOnWindowFocus: false})
    const queryParams = new URLSearchParams(pagina).toString()
    const  handlePage = (input) => {
        setPagina({id: props,comienzo: input})
    }
    const handlePagePrev = () => {
        if(pagina.comienzo > 1){
            setPagina({id: props,comienzo: pagina.comienzo - 1})
        }
    }
    const handlePageFirst = () => {
            setPagina({id: props,comienzo: 1})
    }
    const handlePageLast = () => {
            setPagina({id: props,comienzo: maxPages})
        
    }
    const handlePageNext = () => {
        if(pagina.comienzo < maxPages){
            setPagina({id: props, comienzo: pagina.comienzo + 1})
        }
    }
    

    if (isLoading) {
        return (
        <Col>
            <CargandoBox />
        </Col>)
    }
    if (isError) {
        return (
        <Col>
            <span>{error.message}</span>
        </Col>)
    }
        let items = [];
        let maxPages = Math.ceil(data.data.total/50)
        let leftSide = pagina.comienzo - 2;
        if(leftSide <= 0 ) leftSide=1;
        let rightSide = pagina.comienzo + 2;
        if(rightSide>maxPages) rightSide = maxPages;
        for (let number = leftSide; number <= rightSide; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pagina.comienzo} onClick={()=>handlePage(number)}>
                <span className={number === pagina.comienzo ? 'text-white' : 'text-black-50'} >
                    {number}    
                </span>
            </Pagination.Item>,
        );
        
        }

  return (
    <Col>
    <Col sm={12} className='bg-primary d-flex rounded py-2 mb-2 text-white text-center font-weight-bold'>
        <Col>Nombre ticket</Col>
        <Col>Correo</Col>
        <Col>Cantidad</Col>
        <Col>Fecha</Col>
    </Col>
    {data.data.result.map((res, index) => {
        return (
            <Col key={index} className='border-bottom rounded d-flex bg-white py-3 mb-1 text-center'>
                <Col>{res.nombre}</Col>
                <Col>
                    {res.email}
                </Col>
                <Col>
                {res.cantidad}
                </Col>
                <Col>
                {moment(res.fecha).format('DD/MM/YY H:mm')}
                </Col>
            </Col>
            )
        })
    }
    <Col sm={12} className='mt-3 rounded' >
    <Pagination size="sm" className=' justify-content-center'>
        <Pagination.First onClick={() => handlePageFirst()} />
        <Pagination.Prev onClick={() =>handlePagePrev()} />
        {items}
        <Pagination.Next onClick={() => handlePageNext()} />
        <Pagination.Last onClick={() => handlePageLast()} />
    </Pagination>
    </Col>
    </Col>
  )
}
