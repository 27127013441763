import EditEventForm from 'components/forms/EditEventForm'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

const EditEvento = () => {
  let { location } = useHistory();
  return (
    <Container>
        <EditEventForm data={location.state} />
    </Container>
  )
}

export default EditEvento