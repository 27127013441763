import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const Dashboard = ({props, evento}) => {
  const ticketDisponible = () =>{
    const data = evento.tickets.map(res => res.cantidad).reduce((partial_sum, a) => partial_sum + a, 0)
    return data
}
    const punto = (input) => {
      if(input === 0 || input === null || input === undefined){
        return 0
      }
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  return (
    <Col>
    <Row className='justify-content-center my-3'>
        <Col md={12}  className='text-center pt-3'>
        <span className='d-block display-2'>${punto(props[0]?.totales)}</span>
        <span className='d-block h3 font-weight-bold  text-danger'>Total de ingresos</span>
        </Col>
        <Col className='text-center pt-3'>
        <span className='d-block display-3'>{props[0]?.ventas ? props[0]?.ventas : 0}</span>
        <span className='d-block h4 font-weight-bold text-danger'>Ventas</span>
        </Col>
        <Col className='text-center pt-3'>
        <span className='d-block display-3'>{ticketDisponible()}</span>
        <span className='d-block h4 font-weight-bold  text-danger'>Tickets disponibles</span>
        </Col>
        <Col  className='text-center pt-3'>
        <span className='d-block display-3'>{props[0]?.tickets ? props[0]?.tickets : 0}</span>
        <span className='d-block h4 font-weight-bold  text-danger'> Tickets vendidos</span>
        </Col>
    </Row>
    </Col>
  )
}
