import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CurrentTickets } from 'components/acount/productor/currentTickets';
import { Info } from 'components/acount/productor/detalle/info';
import { Pagina } from 'components/acount/productor/detalle/pagination';
import { Tickets } from 'components/acount/productor/detalle/tickets';
import { Links } from 'components/acount/productor/links';
// import { TituloExpand } from 'components/acount/productor/tituloExpand';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const queryClient = new QueryClient()

export const VentaDetalle = () => {
    let { location } = useHistory();
  return (
    <QueryClientProvider client={queryClient}>
        <Container>
            <Row>
                <Col>
                    <h2 className=' font-weight-bold'>Ventas</h2>
                </Col>
            </Row>
            <Row>
            <Col md={7} className='d-flex'>
                <Info props={location.state} />
            </Col>
            <Col md={5} className='bg-white rounded py-3'>
                <Links props={location.state} />
            </Col>
            <Col sm={12} className='mt-md-5'>
                <h3>Tickets actuales</h3>
            </Col>
                <CurrentTickets props={location.state.tickets} bg='#fff'  />
            <Col sm={12} className='mt-md-5'>
                <h3>Tickets Vendidos</h3>
            </Col>
            <Tickets props={location.state} />
            <Col sm={12} className='mt-md-5'>
                <h3>Registro de ventas</h3>
            </Col>
            <Pagina props={location.state} />
            </Row>
        </Container>
    </QueryClientProvider>
  )
}
