import { useQuery } from '@tanstack/react-query'
import { getProductorPagina } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
import moment from 'moment'
import React, { useState } from 'react'
import { Badge, Col, Pagination } from 'react-bootstrap'

export const Pagina = ({props}) => {
    const [pagina, setPagina] = useState({id: props.id,comienzo: 1})
    const { isLoading, isError, data, error } = useQuery(['pagination', pagina], () => getProductorPagina(queryParams), {retry: false, refetchOnWindowFocus: false})
    const queryParams = new URLSearchParams(pagina).toString()
    const punto = (input) => {
        if(input === 0 || input === null || input === undefined){
          return 0
        }
          return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const  handlePage = (input) => {
        setPagina({id: props.id,comienzo: input})
    }
    const handlePagePrev = () => {
        if(pagina.comienzo > 1){
            setPagina({id: props.id,comienzo: pagina.comienzo - 1})
        }
    }
    const handlePageFirst = () => {
            setPagina({id: props.id,comienzo: 1})
    }
    const handlePageLast = () => {
            setPagina({id: props.id,comienzo: maxPages})
        
    }
    const handlePageNext = () => {
        if(pagina.comienzo < maxPages){
            setPagina({id: props.id, comienzo: pagina.comienzo + 1})
        }
    }
    

    if (isLoading) {
        return (
        <Col>
            <CargandoBox />
        </Col>)
    }
    if (isError) {
        return (
        <Col>
            <span>{error.message}</span>
        </Col>)
    }
        let items = [];
        let maxPages = Math.ceil(data.total/50)
        let leftSide = pagina.comienzo - 2;
        if(leftSide <= 0 ) leftSide=1;
        let rightSide = pagina.comienzo + 2;
        if(rightSide>maxPages) rightSide = maxPages;
        for (let number = leftSide; number <= rightSide; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pagina.comienzo} onClick={()=>handlePage(number)}>
                <span className={number === pagina.comienzo ? 'text-white' : 'text-black-50'} >
                    {number}    
                </span>
            </Pagination.Item>,
        );
        
        }

  return (
    <Col>
    <Col sm={12} className='bg-primary d-flex rounded py-2 mb-2 text-white text-center font-weight-bold'>
        <Col sm={1}>ID</Col>
        <Col>Tickets</Col>
        <Col>Correo</Col>
        <Col>Cantidad</Col>
        <Col>Fecha</Col>
        <Col>Total de venta</Col>
    </Col>
    {data.result.map((res, index) => {
        return (
            <Col key={index} className='border-bottom rounded d-flex bg-white py-3 mb-1 text-center'>
                <Col sm={1}>{res.id}</Col>
                <Col>
                {res.tickets.map((item, index) => {
                    return(
                        <div key={index} className='mb-1'>
                                    <Badge pill variant="info">
                                        <span className='mr-2'>{item.nombre}</span>
                                        <Badge pill variant='secondary' className='text-info'>{item.cantidad}</Badge>
                                    </Badge>
                                </div>
                                )}
                                )}
                </Col>
                {/* <Col>
                    {res.nombres}
                </Col> */}
                <Col>
                    {res.email}
                </Col>
                <Col>
                    {res.tickets.map(item => item.cantidad).reduce((prev, next) => prev + next)}
                </Col>
                <Col>
                {moment(res.created_at).format('DD/MM/YY H:mm')}
                </Col>
                <Col>
                ${punto(res.tickets.map(item => item.valor * item.cantidad).reduce((prev, next) => prev + next))}
                </Col>
            </Col>
            )
        })
    }
    <Col sm={12} className='mt-3 rounded' >
    <Pagination size="sm" className=' justify-content-center'>
        <Pagination.First onClick={() => handlePageFirst()} />
        <Pagination.Prev onClick={() =>handlePagePrev()} />
        {items}
        <Pagination.Next onClick={() => handlePageNext()} />
        <Pagination.Last onClick={() => handlePageLast()} />
    </Pagination>
    </Col>
    </Col>
  )
}
