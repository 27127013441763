import { getCompras } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

const IsAuthTusCompras = () => {
    const [listaCompras, setlistaCompras] = useState(null)

    const punto = (input) => {
        if(input === 0 || input === null || input === undefined){
            return 0
          }
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    useEffect(() => {
        let ignore = false
        if(!ignore){
          const tomardatos = async()=>{
            let datos = await getCompras()
            setlistaCompras(datos)
          }
          tomardatos()
        }
        return ()=>{ignore = true}
      }, [])
      if(listaCompras === null){
        return(
          <Col md={12} className='bg-white rounded p-5' >
                <CargandoBox />
            </Col>
        )
      }
      if(listaCompras.length > 0){
          return(
              <Col>
              {listaCompras.map((item, i) =>{
                  return (
                    <div key={i} className=' rounded border mb-5 px-3 pt-5  mx-auto bg-white'>
                    <Row>
                        <Col md='3' className='text-center mb-3'>
                            {item.evento?.imagen?.url ? <img height={200} src={process.env.REACT_APP_API + item.evento.imagen.url} alt="" /> :'sin imagen' }
                        </Col>
                        <Col md='9'>
                            <Row >
                                <Col md='4' className='text-center'>
                                    <h6 className='text-black-50'>Identificador</h6>
                                    <h4>{item.id}</h4>
                                </Col>
                                <Col md='4' className='text-center'>
                                    <h6 className='text-black-50'>Evento</h6>
                                    <h4 className='text-primary'>{item.evento.titulo}</h4>
                                </Col>
                                <Col md='4' className='text-center'>
                                    <h6 className='text-black-50'>Pagado</h6>
                                    <h3 className='text-primary font-weight-bold'>${punto(item.total)}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <h6 className='text-black-50'>cantidad</h6>
                                    <h4 className=''>{item.tickets.map(item => item.cantidad).reduce((prev, next) => prev + next)} tickets</h4>
                                </Col>
                                <Col md={4} className='text-center'>
                                    <h6 className='text-black-50'>tickets</h6>
                                    {item.tickets.map((item, index) => {
                                return(
                                        <div key={index} className='compra-pills'>
                                            <div>{item.nombre}</div>
                                            <div className='circle'>X{item.cantidad}</div>
                                        </div>
                                    
                                    )}
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='border mt-5 py-3 bg-light'>
                        <Col md='4' className='text-center'>
                            <h6 className='text-black-50'>enviado al Correo</h6>
                            <h4 className=''>{item.email}</h4>
                        </Col>
                        <Col md='4' className='text-center'>
                            <h6 className='text-black-50'>nombres</h6>
                            <h4 className=''>{item.nombres}</h4>
                        </Col>
                        <Col md='4' className='text-center'>
                            <h6 className=''>fecha de compra</h6>
                            <h4 className=' font-weight-bold text-white'>{moment(item.created_at).format('DD/MM/YY H:mm')}</h4>
                        </Col>
                    </Row>
                    </div>
                  )
              })}
              </Col>
          )
          
      }
      if(listaCompras.length === 0){
        return (
          <Col md={12} className='bg-white rounded p-5' >
            <h2>No tienes compras</h2>
          </Col>
        )
      }
      return(
        <Col className='bg-white rounded p-5 text-center'>
          <CargandoBox />
        </Col>
      )
}

export default IsAuthTusCompras