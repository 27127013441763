import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Add } from 'components/acount/productor/cortesia/add';
import { PaginaCortesia } from 'components/acount/productor/cortesia/pagination';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const queryClient = new QueryClient()

export const Cortesia = () => {
    let { location } = useHistory();
    return (
      <QueryClientProvider client={queryClient}>
        <Container>
            <Row>
                <Col>
                    <h2 className=' font-weight-bold'>Cortesia</h2>
                </Col>
            </Row>
            <Row>
                <Col xs='12'><h2 className='h3 mb-3'>Agregar tickets de cortesia</h2></Col>
                <Col><Add props={location.state.id} /></Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h2 className='h3 my-3'>Lista de cortesias enviadas</h2>
                </Col>
                <PaginaCortesia props={location.state.id} />
            </Row>
        </Container>
      </QueryClientProvider>
    )
}
