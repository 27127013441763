import { getTaxes } from 'components/api/service';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Row } from 'react-bootstrap';
import noImage from 'assets/img/no-image.jpg'

const Eventotickets = ({props = []}) => {
  const history = useHistory()
    const [tickets, setTickets] = useState([]);
    const [total, setTotal] = useState([]);
    const [totalProp, setTotalProp] = useState(0);
    const [totalnotax, setTotalnotax] = useState(0);
    const [currentax, setCurrentax] = useState(0);
    // eslint-disable-next-line
    useEffect(async() => {
      let ignore = false
      if(!ignore){
        setTickets(props.tickets)
        await getTaxes().then(res => setCurrentax(res))
      }
      return () =>{ ignore = true}
    // eslint-disable-next-line
    }, [props]);

    const goTo = () =>{
      history.push('/comprar', {props, total, totalProp, currentax, totalnotax})
    }
    const result = (input) => {
      let mytotal = 0
      for (var i = 0; i < input.length; i++) {
        mytotal = mytotal + (input[i].cont * input[i].valor);
        }
        setTotalProp(totalTaxes(mytotal))
        setTotalnotax(mytotal)
    }
    const handleChange = (e) =>{
      if(total.length === 0){
        const data = tickets.map(res => {
          let prue = {}
          prue.id = res.id
          prue.valor = res.valor
          prue.nombre = res.nombre
          prue.cont = 0
          return prue
        })
        const totalstore1 = data.map(el => {if( el.id === parseInt(e.target.id)){
          let otro = el
          otro.cont = parseInt(e.target.value)
          return otro
        }else{return el}}
        )
        setTotal(totalstore1)
        return result(totalstore1)
      }
      else{
        const totalstore = total.map(el => {if( el.id === parseInt(e.target.id)){
          let otro = el
          otro.cont = parseInt(e.target.value)
          return otro
        }else{return el}}
        )
        setTotal(totalstore)
        result( totalstore)

      }
    }
    const totalTaxes = (input) =>{
      let data = input * (currentax) + input
        return data
    }
    const punto = (input) => {
      if(input === 0 || input === null || input === undefined){
        return 0
      }
      return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const counterRow = (input) =>{
      let data = 0
      data = input.length
      if(data === 0){return true}
      if(data === 1){return 10}
      if(data >= 4){return 6}
      else{return true}
    }
  // const renderTickets = () =>{
  //  return tickets?.map((res, index) =>{
  //     if(res.cantidad > 0){
  //       return (
  //         <Row key={index} className='rounded bg-dark text-white mb-2 p-1'>
  //             <Col md='auto'><h3 className='text-white m-0'>{res.nombre}</h3></Col>
  //             <Col md='auto' className='text-center'>tickets disponibles <b>{res.cantidad}</b></Col>
  //             <Col md='auto'><h4 className='text-primary text-center bg-white rounded'>Valor {res.valor}</h4></Col>
  //             <Col md='auto'>
  //               <select key={index} id={res.id} name={`cantidad-${res.id}`} className='h3 rounded' onChange={handleChange} >
  //                 <option value="0">0</option>
  //                 <option value="1">1</option>
  //                 <option value="2">2</option>
  //                 <option value="3">3</option>
  //                 <option value="4">4</option>
  //                 <option value="5">5</option>
  //               </select>
  //             </Col>
  //         </Row>
  //       )
  //      }
  //  })
  // }
  return (
  <Row xs={12} md={2} lg className=' justify-content-center align-items-center'>
      {  
          tickets?.map((res, index) =>{
              return (
                <Col lg={counterRow(tickets)} key={index}>
                  <div className={res.cantidad > 0 ?'rounded bg-primary text-white mb-4 p-4': 'rounded bg-light text-white mb-4 p-4'}>
                    <Row>
                      <Col lg='8' xs className={res.cantidad > 0 ? ' b-ticket' : ''}>
                        <h2 className=' text-white mb-2 text-capitalize font-weight-bold'>{res.nombre}</h2>
                        <span className='mb-3 p-1 rounded-pill px-3 bg-white-50 text-white'>{res.cantidad > 0 ? <>Disponibles <strong>{res.cantidad}</strong></>: 'agotado'}</span>
                        <span className='mt-2 cap d-inline-block d-md-block d-lg-block'>{res.descripcion}</span>
                        <h2 className='text-white mb-0 font-weight-bold mt-3 display-2'>${punto(res.valor)}</h2>
                        <small className='white-50'>Precio no incluye comision</small>
                      </Col>
                      {res.cantidad > 0 ?
                      <Col md='4' xs='4' className='text-center p-3'>
                        <span className='cantidadtickets'>cantidad</span>
                        <select key={index} id={res.id} name={`cantidad-${res.id}`} className='ticketsel' onChange={handleChange} >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        <label htmlFor={res.id} className='d-block'>seleccionar</label>
                      </Col>
                      : ''}
                    </Row>
                  </div>
                </Col>
              )
          })
      }
      {totalProp !== 0 && 
        <div className='boxcompra row col-12 col-md-auto'>
          <Col className=' d-none d-md-block'>
          { props?.imagen !== null ? 
              <img height={100} src={process.env.REACT_APP_API+props?.imagen?.url} alt={props.titulo} />
              :<img src={noImage} alt="no imagen" />
           }
          </Col>
          <Col md='auto' className='text-center'>
            <Col className='h3 mb-0 font-weight-bold'>Total ${punto(totalProp)}</Col>
            <small className='text-gray text-center'>Total incluye comision</small>
            <Col className='btn btn-primary' onClick={()=>{goTo()}}><strong>Comprar Ahora</strong> </Col>
          </Col>
        </div>}
    {/* <Col md='12'>
      <Col className='h3'>Total $ {punto(totalProp)}</Col>
      <Col className='btn btn-primary' onClick={()=>{goTo()}} disabled={totalProp === 0} >Comprar</Col>
    </Col> */}
  </Row>
  );
};

export default Eventotickets;
