import IsAuthVentas from 'components/acount/IsAuthVentas'
import React from 'react'
import { Col, Container } from 'react-bootstrap'

const MyEventsVentas = () => {
  return (
    <Container>
        <h2 className=' font-weight-bold'>Tus ventas</h2>
        <Col className='bg-white rounded p-3'>
            <IsAuthVentas />
        </Col>
    </Container>
  )
}

export default MyEventsVentas