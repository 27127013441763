import { getVentaProductor } from 'components/api/service'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import IsAuthCompraMap from './IsAuthCompraMap'
import { CSVLink} from "react-csv";
import moment from 'moment';
import { CargandoBox } from 'components/general/cargandoBox';
// import IsAuthTicketsMap from './IsAuthTcketsMap';


const IsAuthListEvents = (props) => {
    const id  = props.id
    // console.log('list events', props)
    const [lista, setlista] = useState(null)
    const descarga = ()=>{
      let data = []
      if(lista.length > 0){
        lista.map((res, i) =>{
         return data.push({
            n: i +1,
            id : res.id,
            fecha: moment(res.created_at).format('DD/MM/YYYY H:mm'),
            nombre: res.nombres,
            email: res.email,
            telefono: res.telefono,
            cantidad: res.tickets.map(tick => tick.cantidad).reduce((x, y) => x + y ,0),
            tickets: res.tickets.map(tick => ' ' +tick.nombre + ' X' + tick.cantidad).reduce((x, y) => x+',' + y ,' '),
            total: res.tickets.map(tick => tick.cantidad * tick.valor).reduce((x, y) => x + y ,0),
        })
      })
        return data

      }
    }
    useEffect(() => {
      let ignore = false
      if(!ignore){
        const tomardatos =async()=>{
          let datos = await getVentaProductor(id)
            setlista(datos)
        }
        tomardatos()
      }
      return ()=>{ignore = true}
    }, [id])

    const ticketVendidos = () =>{
      if(lista.length > 0){
        let data = lista.map(res => res.tickets.map(item => item.cantidad).reduce((prev, next) => prev + next))
        return data.reduce((prev, next) => prev + next)
      }
    }
    const punto = (input) => {
      return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    if(lista === null){
      return(
        <Col md={12} className='bg-white rounded p-5' >
          <CargandoBox />
        </Col>
       
      )
    }
    if(lista.length > 0){
      return (
        <Col md={12} className='bg-white rounded p-5' >
          <Col className='mb-5'>
            <Row>
              <Col md={3} className='mx-auto rounded border bg-info p-4 text-center'>
                <h4 className='text-white-50'>Total de ventas  &nbsp;</h4>
                <span className='text-white h2'>${punto(lista.length >= 0 ? lista.map(item => item.tickets.map(res => res.cantidad * res.valor).reduce((prev, next) => prev + next)).reduce((prev, next) => prev + next) : 0)}</span>
              </Col>
              <Col md={3} className='mx-auto rounded border bg-danger p-4 text-center'>
                <h4 className='text-white'>Ticket vendidos &nbsp;</h4>
                <span className='text-white h2'>{ticketVendidos()}</span>
              </Col>
              <Col md={3} className='mx-auto rounded border bg-warning p-4 text-center'>
                <h4 className=' text-white'>Numero de ventas &nbsp;</h4>
                <span className='text-white h2'>{lista.length}</span>
              </Col>
              <Col md={3} className='mx-auto rounded border bg-info p-4 text-center'>
                <h4 className=' text-white'>Plantilla CSV</h4>
                <CSVLink className='btn bg-white text-info' data={descarga()} filename={`${props.titulo}.csv`}>Descargar</CSVLink>
                {/* <span className='text-white h2'>${punto(lista.map(item => item.total).reduce((prev, next) => prev + next) - lista.map(res => res.tickets.map(item => item.valor * item.cantidad).reduce((prev, next) => prev + next)).reduce((prev, next) => prev + next))}</span> */}
              </Col>
            </Row>
          </Col>
          {/* <h3>Detalle de Tickets</h3>
          <IsAuthTicketsByComprasMap list={lista} tickets={props.tickets} /> */}
          {/* <h3>Detalle de Tickets</h3>
          <IsAuthTicketsMap list={lista} tickets={props.tickets} /> */}
          <h3>Detalle de ventas</h3>
          {<IsAuthCompraMap list={lista} /> }
        </Col>
      )
    }
    if(lista.length === 0){
      return(
        <Col md={12} className='bg-white rounded p-5' >
          <h2 className='text-center'>El evento no contiene registros de ventas</h2>
        </Col>
      )
    }
    
}

export default IsAuthListEvents