import React, {useState} from 'react'
import { Col, Row, InputGroup, Container, Button, Alert} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { userlogin } from 'redux/actions/AuthActions';
import { useDispatch } from 'react-redux';


const LoginForm = () => {
  const dispatch = useDispatch()
  const [mostrarerror, setMostrarerror] = useState(false)
  // const [enviado, setEnviado] = useState(false)

  const schema = Yup.object({
    email: Yup.string().email('Correo de email invalido').required('Requerido'),
    password: Yup.string().min(6, 'Debe tener 6 caracteres minimos').required('Requerido'),
  })
  return (  
    <>
    <Container className='my-5 flex'>
      <Row className=' d-flex justify-content-center'>
        <div className='col-md-6'>
          <Col md={12} className='pl-0'>
            <h2 className="font-weight-bold">Iniciar sesión</h2>
          </Col>
          <Col className="bg-white rounded p-5">
            {mostrarerror && <Alert variant="danger" onClose={()=>{setMostrarerror(false)}} dismissible>Correo o contraseña invalida</Alert>}
            <Formik
              validationSchema={schema}
              onSubmit={ (values) =>{
                dispatch(userlogin(values)).then(res =>{
                  //  history.push('/')
                  }).catch(setMostrarerror(true))
                
              }}
              initialValues={{
                email: '',
                password: '',
              }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isSubmitting,
            dirty,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormik05">
                  <Form.Label>Correo electronico</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationFormik06">
                  <Form.Label>Contraseña</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder="Contraseña"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Button type="submit" disabled={ !isValid || !dirty}>Iniciar sesion</Button>
            </Form>
          )}
            </Formik>
            <div className='row'>
              <a href="/recuperar-cuenta"  className='col-6 d-block mt-5'><small>¿Olvidaste tu contraseña?</small></a>
              <a href="/registro" className='col-6 d-block mt-5 text-right'><small>Regístrate ahora</small></a>
            </div>
          </Col>
        </div>
      </Row>
    </Container>
  </>
  )
}

export default LoginForm