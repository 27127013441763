import { getListaPagina } from "components/api/service";
import React, { useEffect, useState } from "react";
import { Nav, Container, Row, Col } from "react-bootstrap";
import { IoLogoFacebook, IoLogoInstagram} from "react-icons/io5"

const Footer = () => {
  const [listaPages, setListaPages] = useState([])
  useEffect(() => {
    let ignore = false
    if(!ignore){
      const tomardatos = async()=>{
        let datos = await getListaPagina()
        if(datos.length > 0){
          setListaPages(datos)
        }
      }
      tomardatos()
    }
    return ()=>{ignore = true}
  }, [])




  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3">
            <img height={60} src={require('assets/img/logo-ticketone-name.png').default} alt="" />
          </Col>
          <Col md="3">
            <h4 className="text-white mb-0">Menu</h4>
            <Nav>
              <Nav.Item>
                <Nav.Link href="/">
                  Eventos
                </Nav.Link>
              </Nav.Item>
              {listaPages.length > 0 ? 
                listaPages.map((item, i) =>{
                  return(
                    <Nav.Item key={i}>
                      <Nav.Link href={`${process.env.REACT_APP_APP}/informacion/${item.url}`}>
                        {item.nombre}
                      </Nav.Link>
                    </Nav.Item>
                  )
                  }) 
              :''
              }
              <Nav.Link href="mailto:contacto@ticketone.cl">
                  contacto@ticketone.cl
                </Nav.Link>
              <Nav.Link href="mailto:soporte@ticketone.cl">
                  soporte@ticketone.cl
                </Nav.Link>
              <Nav.Link href="mailto:devolucion@ticketone.cl">
                  devolucion@ticketone.cl
                </Nav.Link>
            </Nav>
          </Col>
          <Col md="3">
            <h4 className="text-white mb-0">Aceso</h4>
            <Nav>
              <Nav.Item>
                <Nav.Link href="/login">
                  Iniciar Sesion
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/registro-productor">
                  Registro de productores
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/registro">
                  Registro de usuario
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/">
                  Descargar app
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md="3">
              <a className="text-white mr-3" href="https://www.facebook.com/TicketOne-chile-1920812364857358/" target="_blank" rel="noreferrer">
                <IoLogoFacebook size={35} />
              </a>
              <a className="text-white mr-3" href="https://www.instagram.com/ticketone_chile/" target="_blank" rel="noreferrer">
                <IoLogoInstagram size={35} />
              </a>
              {/* <a className="text-white" href="/" target="_blank">
                <IoLogoTwitter size={35} />
              </a> */}
          </Col>
        </Row>
        
      </Container>
      <Container>
      <Row>
          <Col className="mt-5 text-white-50">
            Desarrollado por <a href="https://www.dazmoob.com" >Dazmoob SPA</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
export default Footer
