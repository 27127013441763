import moment from 'moment'
import 'moment/locale/es'
import React, { useEffect, useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { IoCalendar, IoTicket } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';


export const CardEvento = ({...props}) => {
    const history = useHistory()
    const [precio, setprecio] = useState([]);
    const [cantidad, setcantidad] = useState(0);
    const goPage = () =>{
        history.push(`evento/${props.datos.slug}`)
    }
    const getprecio = () =>{
        const data = props.datos.tickets.map(res => res.valor).sort((a, b) => a - b)
        const result = data.length ? "Desde $" + data[0] : ["Sin tickets"]
        setprecio(result)
    }
    const ticketDisponible = () =>{
        const data = props.datos.tickets.map(res => res.cantidad).reduce((partial_sum, a) => partial_sum + a, 0)
        setcantidad(data)
    }
    useEffect(() => {
        let ignore = false
        if(!ignore){
            getprecio()
            ticketDisponible()
        }
        return () =>{ignore = true}
        // eslint-disable-next-line
    }, []);

    


    return (
            <Card className='mb-5 p-2'>
            {props.datos?.imagen !== null ? <img className='imgcard' src={process.env.REACT_APP_API+props.datos?.imagen?.url} alt="" /> :
            <img className='imgcard' src={require("../../assets/img/no-image.jpg").default} alt="no imagen" />}
            <div className='pt-3 d-grid gap-2'>
                {props.datos.titulo ? <h3 className='mb-0 min-title-event'>{props.datos.titulo}</h3> : <h3>Sin datos</h3>}
                {/* {props.datos.inicio ? <div><IoCalendar className='h2 m-0 mr-2' />{moment(props.datos.inicio).format("dddd D MMMM YYYY ")}</div> : <div><i></i> sin datos</div> } */}
                {props.datos.inicio ? <div>
                    <p className='font-weight-bold d-block'>
                        <IoCalendar className='h3 m-0 mr-2 text-primary' />{moment(props.datos.inicio).format("LLLL")} hrs
                    </p>
                </div> : <div><p>sin datos</p></div> }
                <div><p><IoTicket className='h3 m-0 mr-2 text-primary' />{precio}</p></div>
            </div>
                <Button variant="primary" onClick={()=> goPage()} className='text-white bg-yellow-1' disabled={!cantidad}>
                    comprar tickets disponibles ({cantidad})
                </Button>
            </Card>
    )
}
