import { useQuery } from '@tanstack/react-query'
import { getProductorDescargar } from 'components/api/service'
import { getProductorTotalEvent } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
import moment from 'moment'
import React, {  useEffect, useRef, useState } from 'react'
import { Badge, Button, Col } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { IoStar } from 'react-icons/io5'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Totales } from './totales'

export const Info = ({props}) => {
  const [descarga, setDescarga] = useState(false)
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const csvInstance = useRef();
  const { isLoading, isError, data, error } = useQuery(['totales'], () =>  getProductorTotalEvent(props.id), {retry: false, refetchOnWindowFocus: false})
  
  useEffect(() => {
    if (descarga && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setDescarga(false);
      });
    }
  }, [descarga])
    const goToEdit = (input) =>{
      history.push('/cuenta/editar-evento', input)
    }

    const downloadCSV = () => {
       getProductorDescargar(props.id).then((res) => {
        let final = []
            res.map((res, i) =>{
              return final.push({
                  n: i +1,
                  id : res.id,
                  fecha: moment(res.created_at).format('DD/MM/YYYY H:mm'),
                  nombre: res.nombres,
                  email: res.email,
                  telefono: res.telefono,
                  cantidad: res.tickets.map(tick => tick.cantidad).reduce((x, y) => x + y ,0),
                  tickets: res.tickets.map(tick => ' ' +tick.nombre + ' X' + tick.cantidad).reduce((x, y) => x+',' + y ,' '),
                  total: res.tickets.map(tick => tick.cantidad * tick.valor).reduce((x, y) => x + y ,0),
              })
            })
          setDescarga(final)
          setLoading(false);
      })
      
      // const csvFile = new Blob([final], { type: 'text/csv' });
      // const csvURL = URL.createObjectURL(csvFile);
      // const tempLink = document.createElement('a');
      // tempLink.href = csvURL;
      // tempLink.setAttribute('download', `${props.titulo}-descarga.csv`);
      // tempLink.click();
    }

    if (isLoading) {
        return <CargandoBox />
    }
    if (isError) {
        return <span>{error.message}</span>
    }
   
  return (
    <Col className='bg-white rounded py-3'>
        {props.destacado ? <span className='text-primary align-middle'><IoStar /><small> Destacado</small></span> : <span className='text-black-50'><small>Normal</small></span>}
        <h2 className='text-info mb-0 h3'>{props.titulo}</h2>
        <span className='text-gray'>{moment(props.inicio, "YYYYMMDD").endOf('day').fromNow()}{' '}</span><br />
        <Badge pill className=' text-black-50' variant={props.publico ? "success" : "warning"}>{props.publico ? 'evento publicado': 'evento en espera'}</Badge>{''}
        <Badge pill variant="light">{props.mayor_edad ? 'evento +18': 'publico general'}</Badge>{' '}
        {props.privado ? <Badge pill variant="info">evento privado</Badge>: ''}{' '}
        {/* {isLoading ? <Dashboard props={data} evento={props} /> : <CargandoBox />} */}
        <Totales props={data} evento={props} />
        <Button variant='info' size='sm' className='my-3 mr-2' onClick={(e)=>{e.stopPropagation();e.preventDefault();goToEdit(props)}} >Editar evento</Button>
        <Button variant='danger' size='sm' className='my-3' onClick={async() => await downloadCSV()} disabled={new Date() <= new Date(props.inicio)}>{loading ? 'cargando..' :'Descargar CVS'}</Button>
        {descarga ? <CSVLink asyncOnClick={true} data={descarga} ref={csvInstance} filename={`${props.titulo}.csv`} /> : undefined}
        {/* <Button variant='primary' size='sm' className='my-3' onClick={() => downloadCSV(props.id)}>Descargar CVS</Button> */}
        {new Date() <= new Date(props.inicio) ?<span className='ml-3 text-muted'>
             se activara la descarga una vez finalizado el evento
        </span> : ''}
    </Col>
  )
}
