import { combineReducers }  from "redux";
// connected-react-router redux
import { connectRouter } from 'connected-react-router'

import isAuth from './AuthReducer'
import ui from './UiReducer'

const RootReducers = (history) => combineReducers({
    router: connectRouter(history),
    isAuth,
    ui
    
})

export default RootReducers