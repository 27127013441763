import React from 'react'
import { Spinner } from 'react-bootstrap'

export const Cargando = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vw-100 min-vh-100">
                <div className="row align-items-center justify-content-center">
                    <Spinner animation="border" role="status" size="lg" variant="primary" className="" />
                    <h4 className="col-12 text-center mt-2">Cargando</h4>
                </div>
            </div>
  )
}
