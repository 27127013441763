import moment from 'moment'
import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { IoEye, IoTicket } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'

const IsAuthVentasMap = ({...props}) => {
    const history = useHistory()
    let lista = []
    lista = props.list
    // console.log('lista', lista)
    const punto = (input) => {
        if(input === 0 || input === null || input === undefined){
            return 0
          }
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    const totalVendidos = (input) =>{
        if(input.length > 0){
          let data = 
          input.map((res) =>  res.tickets.map(item => item.cantidad * item.valor)
          .filter(x => x > 0).reduce((x, y) => x + y, 0)
          )
          .reduce((prev, next) => prev + next)
          return data
        }else{
            return 0
        }
    }
    const ticketVendidos = (input) =>{
        if(input.length > 0){
          let data = 
          input.map((res) =>  res.tickets.map(item => item.cantidad)
          .filter(x => x > 0).reduce((x, y) => x + y, 0)
          )
          .reduce((prev, next) => prev + next)
          return data
        }else{
            return 0
        }
    }
    const goTo = (input) =>{
        history.push('/cuenta/evento/compras', input)
    }
    const goTicket = (input) =>{
        history.push('/cuenta/evento/tickets', input)
    }
  return (
    <Table striped  hover>
        <thead >
            <tr className='bg-primary text-white rounded'>
                <th className='text-center'>id</th>
                <th className='text-center'>titulo</th>
                <th className='text-center'>Publico</th>
                <th className='text-center'>Fecha inicio</th>
                <th className='text-center'>Ventas</th>
                <th className='text-center'>tickets Vendidos</th>
                <th className='text-center'>Total de venta</th>
                <th className='text-center'>Ver</th>
            </tr>
        </thead>
        <tbody className='text-center'>
        {lista.map((res, index) =>{
            return(
                <tr key={index}>
                <td>{res.id}</td>
                <td>{res.titulo}</td>
                <td>{res.publico ? 'si': 'no'}</td>
                <td>{moment(res.inicio).format('DD/MM/YY H:mm')}</td>
                <td>{res.compras.filter(x => x.validado === true).length}</td>
                <td>{ticketVendidos(res.compras.filter(x => x.validado === true))}</td>
                <td>${punto(totalVendidos(res.compras.filter(x => x.validado === true)))}</td>
                <td>
                    <Button size='sm' variant='warning' onClick={() => goTo(res)} className=' rounded-pill p-1'><IoEye size={20} /></Button>
                    <Button size='sm' variant='warning' onClick={() => goTicket(res)} className=' rounded-pill p-1'><IoTicket size={20} /></Button>
                </td>
                {/* <td>{res.id}</td> */}
                {/* <td>{punto(res.total - res.tickets.map(item => item.cantidad * item.valor).reduce((prev, next) => prev + next))}</td> */}
                {/* <td>${punto(res.total)}</td> */}
                </tr>
            )
        })}
        </tbody>
    </Table>
  )
}

export default IsAuthVentasMap