import React from 'react'
import { Col } from 'react-bootstrap'
import { IoDownload, IoImage, IoMail, IoMedkit } from 'react-icons/io5'

export const AddEventhelp = () => {
  return (
    <>
    <div className='h2 d-block'></div>
    <Col className='p-5'>
    {/* <h2 className='text-primary'><IoHelpCircle size={40}/> Ayuda</h2> */}
    <div className='mb-md-5'>
        <div className='mb-3'>
          <IoMedkit className='text-primary' size={40} /><span className='h3 ml-3 mb-0 text-primary'>Kit Productor</span> <br />
        </div>
        <p>Tenemos un kit con los archivos necesarios para ayudar en la creacion del evento</p>
        <p>El archivo contiene distintas imagenes para que puedas editarlas de forma profesional</p> <br />
        <a className='btn btn-info font-weight-bold' href="/docs/Productor-kit.zip"><IoDownload className='mr-3' size={20} /> Descargar kit</a>
      </div>
      <div>
        <div className='mb-3'>
          <IoImage className='text-primary' size={40} /><span className='h3 ml-3 mb-0 text-primary'>Imagenes</span> <br />
        </div>
        <p>Las imagenes tienen que tener un peso inferior a <strong>1MB </strong>
        te recomendamos que optimices las imagenes para su version web en tu editor de imagenes profesional</p> 
        <p>Si tienes dudas o necesitas ayuda con la edicion de imagenes toca el siguiente boton </p>
        <br />
        <a className='btn btn-info' href="mailto:contacto@ticketone.cl"><IoMail className='mr-3' size={20} /> Solicitar ayuda</a> <br className='mb-5' />
      </div>
    </Col>
    </>
  )
}
