import PerfilUser from 'components/acount/PerfilUser'
import PerfilProductor from 'components/acount/PerfilProductor'
import React from 'react'
import { Container } from 'react-bootstrap'

const Profile = ({...props}) => {
  const usuario = props.auth.user
  if(usuario.productor){
    return(
      <Container>
        <h2><strong>Editar Perfil productor </strong><strong className='text-primary'>{usuario.nombres}</strong></h2>
        <PerfilProductor user={usuario} />
      </Container>
    )
  }
  return (
    <Container>
      <h2><strong>Tú perfil</strong></h2>
      <PerfilUser user={usuario} />
    </Container>
  )
}

export default Profile