import moment from 'moment'
import React from 'react'
import { Table } from 'react-bootstrap'

const IsAuthCompraMap = ({...props}) => {
    let lista = []
    lista = props.list
    const punto = (input) => {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  return (
    <Table striped  hover>
        <thead >
            <tr className='bg-primary text-white rounded'>
                <th>ID</th>
                <th>Tickets</th>
                <th>Cantidad</th>
                <th>Fecha</th>
                <th>Correo</th>
                <th>Total de venta</th>
            </tr>
        </thead>
        <tbody className='text-center'>
        {lista.map((res, index) =>{
            return(
                <tr key={index}>
                    <td>{res.id}</td>
                    <td>{res.tickets.map((item, index) => {
                        return(
                            <div key={index} className='col'>
                                <div className='pills'>
                                    <div>{item.nombre}</div>
                                    <div className='circle'>X{item.cantidad}</div>
                                </div>
                            </div>
                            
                            )}
                            )}
                    </td>
                    <td>{res.tickets.map(item => item.cantidad).reduce((prev, next) => prev + next)}</td>
                    <td>{moment(res.created_at).format('DD/MM/YY H:mm')}</td>
                    <td>{res.email}</td>
                    <td>${punto(res.tickets.map(item => item.valor * item.cantidad).reduce((prev, next) => prev + next))}</td>
                </tr>
            )
        })}
        </tbody>
    </Table>
  )
}

export default IsAuthCompraMap