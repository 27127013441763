import IsAuthCompra from 'components/acount/IsAuthCompra'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

const MyEventsCompra = () => {
  let { location } = useHistory();
  const DatosEvento = location.state
  return (
    <Container>
        <h2 className=' font-weight-bold'>Compras en el evento <span className='text-primary'>{DatosEvento.titulo}</span></h2>
        <IsAuthCompra id={DatosEvento.id} titulo={DatosEvento.titulo} tickets={DatosEvento.tickets} />
    </Container>
  )
}

export default MyEventsCompra