import React from 'react'

import Slider from "react-slick";
import { Link } from 'react-router-dom';
import noImage from "../../assets/img/no-image.jpg"

export default function EventosSlider({lista}) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true
    }
    return (
        <>
            <Slider {...settings} className="imgslider w-100" style={{height: window.innerWidth / 4.4}}>
            {lista.filter(x => x.destacado === true).map((res, index) => 
            <Link key={index} to={`evento/${res.slug}`}>
                <div  className="boxslider" >
                    {res?.img_destacado !== null ? <>
                        <img src={process.env.REACT_APP_API+res.img_destacado.url} alt="" className='d-none d-md-block' />
                        <img src={process.env.REACT_APP_API+res.imagen.url} alt="" className='d-block d-md-none' />
                    </>:
                    // {res?.img_destacado !== null ? <img src={process.env.REACT_APP_API+res.img_destacado.formats.large.url} alt="" />:
                        <img src={noImage} alt="no imagen" />
                    }
                    {/* <span className='texto'>{res?.titulo}</span> */}
                </div>
            </Link>
            )}
            </Slider>
        </>
    )
}
