// import api  from '../../componentes/api'
// import { push } from 'connected-react-router'
// import Axios from 'axios';

export const COLLAPSE_MENU_REQUEST = "COLLAPSE_MENU_REQUEST";
export const COLLAPSE_MENU_CHANGE = "COLLAPSE_MENU_CHANGE";

export const ac_collapse_menu_request = (data) => {
  return {
    type: COLLAPSE_MENU_REQUEST,
    payload: data,
  };
};
export const ac_collapse_menu_change = (data) => {
  return {
    type: COLLAPSE_MENU_CHANGE,
    payload: data,
  };
};

const collapseMenuRequest = () => {
  return async (dispatch, getstate) => {
    const estado_collapsed_menu = await getstate().ui.collapsed_menu
    const nuevadatamenu =  (localStorage.getItem("menu_expand"))
    if (nuevadatamenu === null || undefined) {
      dispatch(ac_collapse_menu_request(estado_collapsed_menu))
      localStorage.setItem("menu_expand", estado_collapsed_menu)
    } else {
      dispatch(ac_collapse_menu_request(JSON.parse(nuevadatamenu)))
    }
  };
};
const collapse_menu_change = () => {
  return async (dispatch, getstate) => {
    const estado_collapsed = await getstate().ui.collapsed_menu
    const estadolocal = await localStorage.getItem("menu_expand")
    if (estadolocal === null || undefined) {
      // console.log("esta vacio el localstore")
      await localStorage.setItem("menu_expand", !estado_collapsed)
      dispatch(ac_collapse_menu_request(!estado_collapsed))
    } else {
      await localStorage.setItem("menu_expand", !estado_collapsed)
      dispatch(ac_collapse_menu_change(!estado_collapsed))
    }
  };
};

export { collapseMenuRequest, collapse_menu_change };
