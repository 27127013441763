import React, { useState } from 'react'
import { Col, Row, InputGroup, Container, Button, Alert, Image} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { postComprar } from 'components/api/service';
import moment from 'moment';
// import { IoCalendar, IoTrailSign } from 'react-icons/io5';


const RegistroCompra = () => {

  let history = useHistory()
  const { location } = useHistory()
  const [mostrarerror, setMostrarerror] = useState(null);
  const DatosEvento = location.state
  // console.log('pasado a compra', location.state);

  const envioForm = async(input) =>{
    let data = input
    data.evento = DatosEvento.props.id
    data.total = DatosEvento.totalnotax
    data.tickets = DatosEvento.total
    await postComprar(data).then((res) => {
      window.location = res
    }).catch((err) => {
      // console.log('err',err)
    })
  }
  const punto = (input) => {
    if(input === 0 || input === null || input === undefined){
      return 0
    }
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  if(location.state === undefined){
    history.push('/')
    return(
      <></>
    )
  }
 
  //validador de telefono
  const phoneValidador = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  // Yup verifiacion de datos
  const schema = Yup.object({
    nombres: Yup.string()
      .min(6, 'Debe tener 6 caracteres minimos')
      .required('Requerido'),
    telefono: Yup.string()
      .matches(phoneValidador, 'Solo numeros')
      .min(9, 'Debe tener 9 numeros minimos')
      .max(12, 'Debe tener 12 numeros maximos')
      .required('Requerido'),
    rut: Yup.string()
      .min(11, 'Debe tener punto y guion')
      .required('Requerido'),
    email: Yup.string().email('Correo de email invalido').required('Requerido'),
    emailConfirm: Yup.string().required('Requerido')
     .oneOf([Yup.ref('email'), null], 'email debe ser igual'),
     term: Yup.boolean().oneOf([true],'tienes que aceptar los terminos y condiciones')
  })
  return (  
    <Container className='my-5 my-lg-0 pt-1 pt-lg-0'>
      <Row>
        <Col lg='12' className='mb-lg-5'>
          <div className="bg-gray rounded ml-lg-3">
            <Row>
              <Col lg='2' className='mx-lg-auto text-center p-lg-0'>
                { DatosEvento.props.imagen !== null ?
                  <Image className='float-lg-left mt-3 mt-lg-0' rounded={false} height='150' width='150' src={process.env.REACT_APP_API+DatosEvento.props.imagen.url} /> : 
                  <img src={require("../assets/img/no-image.jpg").default} alt="no imagen" />
                }
              </Col>
              <Col xs='12' lg className='py-lg-3 mt-3 mt-lg-0 text-center text-lg-left'>
                <small className='text-gray'>Evento</small>
                <h2 className='cap font-weight-bold'>{DatosEvento.props.titulo}</h2>
              </Col>
              <div className='px-5 py-3 my-2 row d-none d-lg-inline-flex'>
                    <div className='col-12 pl-3'>
                        <small className='text-gray'>Ubicacion</small>
                        <h4 className='cap mb-0 font-weight-bold'>{DatosEvento.props.ubicacion}</h4>
                        <h4 className='cap mb-0'>{DatosEvento.props.region} - {DatosEvento.props.ciudad}</h4>
                    </div>
              </div>
              <div className='col-3 px-4 py-3 my-2 row d-none d-lg-inline-flex'>
                    <div className='col-12'>
                        <small className='text-gray'>Fecha</small>
                        <h4 className='cap mb-0 font-weight-bold'>{moment(DatosEvento.props.inicio).format('dddd DD MMMM YYYY H:mm')} Hrs</h4>
                    </div>
              </div>
            </Row>
          </div>
        </Col>
        <Col lg={{span: 6, order:1}}  xs={{span: 12, order:2}}>
          <div className="bg-white rounded p-5 mb-5">
          <h3>Datos del Comprador</h3>
            {mostrarerror && 
              <Alert variant="danger" onClose={()=>{setMostrarerror(null)}} dismissible>{mostrarerror}</Alert>
            }
            <Formik
              validationSchema={schema}
              onSubmit={ res => envioForm(res)}
              initialValues={{
                email: '',
                emailConfirm: '',
                nombres: '',
                rut: '',
                telefono: '',
                term: false
              }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            isSubmitting,
            dirty,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormik01">
                  <Form.Label>Nombre Completo</Form.Label>
                  <Form.Control
                    type="text"
                    name="nombres"
                    value={values.nombres}
                    onChange={handleChange}
                    isInvalid={!!errors.nombres}
                  />
                  <Form.Control.Feedback>Listo</Form.Control.Feedback>
                </Form.Group>
              
                <Form.Group as={Col} md="5" controlId="validationFormik03">
                  <Form.Label>Rut</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="rut"
                      name="rut"
                      value={values.rut}
                      onChange={handleChange}
                      isInvalid={!!errors.rut}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.rut}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              
                <Form.Group as={Col} md="7" controlId="validationFormik05">
                  <Form.Label>Telefono</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="telefono"
                      name="telefono"
                      value={values.telefono}
                      onChange={handleChange}
                      isInvalid={!!errors.telefono}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.telefono}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik05">
                  <Form.Label>Email</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik05">
                  <Form.Label>Confirmar Email</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="emailConfirm"
                      value={values.emailConfirm}
                      onChange={handleChange}
                      isInvalid={!!errors.emailConfirm}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.emailConfirm}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Check
                  name="term"
                  label="acepta los termino y condiciones"
                  onChange={handleChange}
                  value={values.term}
                  isInvalid={!!errors.term}
                  feedback={errors.term}
                />
                <Form.Control.Feedback type="invalid">
                      {errors.term}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={isSubmitting || !isValid || !dirty} >Comprar</Button>
            </Form>
          )}
            </Formik>
          </div>
        </Col>
        <Col lg="6" xs={{order:1}}>
          <div className='pr-lg-3 px-3'>
              <div className='row bg-light rounded-top p-3'>
                <small className=' font-weight-bold col-6 m-0'>Ticket</small>
                <small className=' font-weight-bold col-2 text-center'>Cantidad</small>
                <small className=' font-weight-bold col-4 text-right'>Valor total</small>
              </div>
                {DatosEvento.total.filter(item => item.cont > 0).map((res, index) =>
                  <div key={index} className='row bg-gray border rounded p-3 mb-1'>
                    <h3 className='col-6 m-0'><strong>{res.nombre}</strong></h3>
                    <h4 className='col-2 text-center'>{res.cont}</h4>
                    <h4 className='col-4 text-right'>${punto(res.valor * res.cont)}</h4>
                    {/* <hr className='w-100 mt-0' /> */}
                    <p className='col-6 text-gray m-0'>Comision</p>
                    <p className='col-6 text-right text-gray m-0 font-weight-bold'>${res.valor * res.cont  * DatosEvento.currentax}</p>
                  </div>
                )}
                <div className='bg-primary row p-3 rounded-bottom'>
                  <h3 className=' font-weight-bold col-6 m-0 text-white'>Total a pagar</h3>
                  <h3 className=' font-weight-bold col-6 m-0 text-white text-right'>${punto(DatosEvento.totalProp)}</h3>
                </div>
          </div>
        </Col>
      </Row>
    </Container>
  )

};

export default RegistroCompra;
