import RegProductor from 'components/forms/regProductor';
import ProductorHelp from 'components/help/ProductorHelp';
import React, { useState } from 'react'
import { Container} from 'react-bootstrap'


const RegistroProductor = () => {
  const [acepta, setAcepta] = useState(false)

const changeAcepta = () =>{
  setAcepta(!acepta)
}


if(acepta){
  return (  
    <Container className='my-5'>
      <RegProductor />
    </Container>
  )
}
return(
  <Container className='my-5'>
    <ProductorHelp action={changeAcepta} />
  </Container>
)

};

export default RegistroProductor
