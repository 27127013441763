import React from 'react'
import { Button, Col } from 'react-bootstrap'

const ProductorHelp = ({...props}) => {
  return (
    <Col className='rounded reg-prod-bg p-5 my-5 d-flex mx-auto  align-items-center'>
        <div className='col-md-6'>
            <h1 className='text-white font-weight-bold'>Registro de Productor</h1>
            <h4 className='text-white my-5'>Disfruta mientras nuestra tecnológica se encarga del resto.
Cada día trabajamos duro para ofrecerte las herramientas que te permitan dar a tus fans la mejor experiencia<br /></h4>
            <Button onClick={props.action} size='lg' variant='primary' className='mt-5'><strong>Registrarse como productor</strong></Button>
        </div>
    </Col>
  )
}

export default ProductorHelp