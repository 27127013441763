import { COLLAPSE_MENU_REQUEST, COLLAPSE_MENU_CHANGE } from "../actions/UiActions"

const default_ui = {
    // loading: true,
    collapsed_menu: false,
    first_time:false
}
const ui = (state = default_ui, action) => {
    switch (action.type){
        case COLLAPSE_MENU_REQUEST: 
            return {
                ...state,
                collapsed_menu: action.payload
            }
        case COLLAPSE_MENU_CHANGE: 
            return {
                ...state,
                collapsed_menu: action.payload
            }
        default: return state;
        }
    }

export default ui