import React from 'react'
import { Table } from 'react-bootstrap'
import { IoCheckmarkCircle, IoWarning } from 'react-icons/io5'

const IsAuthTicketsByComprasMap = ({...props}) => {
    let lista = []
    let tickets = []
    lista = props.list
    tickets = props.tickets
    let contTickets = lista.map(item => item.tickets).flat(9)
    const punto = (input) => {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    // const groupBy = (items, key) => items.reduce(
    //     (result, item) => ({
    //       ...result,
    //       [item[key]]: [
    //         ...(result[item[key]] || []),
    //         item,
    //       ],
    //     }), 
    //     {},
    //   );
    //   const groupedMap = contTickets.reduce(
    //     (entryMap, e) => entryMap.set(e.id, [...entryMap.get(e.id)||[], e]),
    //     new Map()
    // );
    // const mergedArray = Array.from(
    //     contTickets.reduce(
    //         (entryMap, e) => entryMap.set(e.id, {...entryMap.get(e.id)||{}, ...e}),
    //         new Map()
    //     ).values()
    // );
    
    // const groupBy = (data) => {
    //     const result = data.reduce((agrupado, item) => {
    //           const { nombre, valor, cantidad } =   item;
    //           // eslint-disable-next-line
    //           const prevItem = agrupado[nombre, cantidad] || {};
    //           const {cantidad: prevQuantity = 0} = prevItem;
    //           // eslint-disable-next-line
    //           agrupado[nombre, valor] = {
    //                 ...item,
    //                 nombre: nombre,
    //                 valor: valor,
    //                 cantidad: prevQuantity + cantidad,
    //                 total: (prevQuantity + cantidad) * valor,
    //           };
    //         //   console.log('agrupado', agrupado)
    //           return agrupado;
    
    //     }, {});
    //     // console.log('result', result)
    //     return Object.values(result);
    // }
    
    const resultado = Array.from(contTickets.reduce((r, o) => {
        // const key = o.nombre;
        const key = o.nombre + '-' + o.valor;
        const item = r.get(key) || Object.assign({}, o, {
          cantidad: 0,
          valor: 0,
          total: 0
        });
        delete item.qr
        delete item.id
        item.cantidad += o.cantidad;
        item.valor = o.valor;
        item.total += o.cantidad * item.valor;
      
        return r.set(key, item);
      }, new Map()).values()
      )
    //   console.log('resultado', resultado);
    
  return (
    <Table striped  hover className='mb-5'>
        <thead >
            <tr className='bg-primary text-white rounded'>
                <th className='text-center'>Estado</th>
                <th className='text-center'>Nombre</th>
                <th className='text-center'>Valor</th>
                <th className='text-center'>disponible</th>
                <th className='text-center'>Comprados</th>
                <th className='text-center'>Ventas</th>
            </tr>
        </thead>
        <tbody className='text-center'>
           { resultado.map((item, index) =>{
               let data = tickets.map((yoyoh) => 
               yoyoh.id === item.ticket_id & yoyoh.valor === item.valor ? yoyoh.cantidad : 0
               ).filter(x => x)
               return(
            <tr key={index}>
                <td>{data > 0 ? <IoCheckmarkCircle size={25} className='text-success' /> : <IoWarning size={25} className='text-warning' />}</td>
                <td>{item.nombre}</td>
                <td className='text-center'>${punto(item.valor)}</td>
                <td>{data >0 ? data : 0}</td>
                <td className='text-center'>{item.cantidad}</td>
                <td>${punto(item.total)}</td>
            </tr>
               )
           } ) }
           
        </tbody>
    </Table>
  )
}

export default IsAuthTicketsByComprasMap