import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { CardEvento } from './card'

export const ListaEventos = ({lista}) => {
           return (
            <>
            <Container>
                <Row>
                    {lista.length > 0 ? lista.map((res, index) =>
                        <Col md={4} key={index}>
                            <CardEvento datos={res} />
                        </Col>
                    ) : "sin datos"}
                </Row>
            </Container>
            </>
        )
   
}
