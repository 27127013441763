import api from '.'

export const getEventos = async() => {
    return await api.get("eventos").then(res =>{
        return res.data
    }).catch(error =>{
        return []
    })
}
export const getEventosProductor = async() => {
    return await api.get('cuenta/eventos',  { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        return []
    })
}
export const getVentasProductor = async() => {
    return await api.get('cuenta/ventas',  { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        return []
    })
}
export const getVentaProductor = async(input) => {
    return await api.get('cuenta/venta/'+input,  { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        return 'error'
    })
}
export const getEvento = async(input) => {
    return await api.get(`eventos/${input}`).then(res =>{
        return res.data
    }).catch(error =>{
        // console.log('error', error.response)
        return []
    })
}
export const getEventoPrivado = async(input) => {
    return await api.get(`privado/${input}`).then(res =>{
        return res.data
    }).catch(error =>{
        // console.log('error', error.response)
        return []
    })
}
export const getPagina = async(input) => {
    return await api.get(`paginas/${input}`).then(res =>{
        return res.data
    }).catch(error =>{
        return []
    })
}
export const getListaPagina = async() => {
    return await api.get(`paginas/`).then(res =>{
        return res.data
    }).catch(error =>{
        return []
    })
}
export const getCompras = async() => {
    return await api.get(`compras/`,  { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        return []
    })
}
export const postRegister = async (input) => {
    return await api.post('auth/local/register', input).then(res =>{
        return 1
    }).catch(res =>{
        if( res.response.status === 400){
        if(res.response.data.message[0].messages[0].id === 'Auth.form.error.email.taken'){
            return 2
        }
    }
        // console.log('error', res);
        // console.log('error 2', res.response.data.message[0].messages[0].id);
        return []
    })
}
export const postRegisterProductor = async (input) => {
    input.solicitud_productor = true
    // console.log('input', input)
    return await api.post('auth/local/register', input).then(res =>{
        return 'ok'
    }).catch(res =>{
        // console.log('res.response', res.response)
            return res.response.status
    })
}
export const postlogin= async (input) => {
    return await api.post('auth/local/', input, { withCredentials: true }).then(res =>{
        return res.data
    }).catch(res =>{
        if( res.response.status === 400){
            return 400
        }
        return res
    })
}
export const lostPass= async (input) => {
    return await api.post('auth/forgot-password', input, { withCredentials: true }).then(res =>{
        return res.data
    }).catch(res =>{
        // console.log('res.response', res.response)
        return 'error'
    })
}
export const activeAccount= async (input) => {
    // console.log('input', input)
    return await api.get(`auth/email-confirmation?confirmation=${input}`, { withCredentials: true }).then(res =>{
        // console.log('activado', res)
        return 200
    }).catch(res =>{
        // console.log('res ==>', res.response)
        return res.response.status
    })
}
export const resendActive= async (input) => {
    return await api.post('auth/send-email-confirmation',{email: input.email}, { withCredentials: true }).then(res =>{
        // console.log('reenvio de correo', res)
        return 200
    }).catch(res =>{
        // console.log('res send active ==>', res.response)
        return res.response.status
    })
}
export const resetPass= async (input) => {
    return await api.post('auth/reset-password',{code: input.code, password: input.password, passwordConfirmation: input.password}, { withCredentials: true }).then(res =>{
        // console.log('reenvio de correo', res)
        return 200
    }).catch(res =>{
        // console.log('res send active ==>', res.response)
        return res.response.status
    })
}
export const postComprar = async (input) => {
    return await api.post('/compras/token', input, { withCredentials: true }).then(res =>{
            return res.data
    }).catch(error =>{
            return []
    })
}
export const getTaxes = async () => {
    return await api.get('/taxes').then(res =>{
        // console.log('service gettaxes', res.data.porcentaje);
            return res.data.porcentaje
    }).catch(error =>{
        // console.log('error.response', error.response);
            return []
    })
}
export const getMe = async () => {
    return await api.get('/users/me', { withCredentials: true }).then(res =>{
            return res.data
    }).catch(error =>{
            return []
    })
}
export const postFinishCompra = async (input) => {
    return await api.post('/compras/finish', input).then(res =>{
            return res.data
    }).catch(error =>{
        // console.log('error.response', error.response);
            return []
    })
}
export const postEvento = async (input) => {
    return await api.post('/eventos', input, { withCredentials: true },{ 'Content-Type': 'multipart/form-data' }).then(res =>{
            // console.log('res', res)
            return 'ok'
    }).catch(error =>{
        // console.log('error.response', error.response);
            return 'error'
    })
}
export const editEvento = async (input, id) => {
    // console.log('input, id', input, id)
    return await api.put('/eventos/'+id, input, { withCredentials: true },{ 'Content-Type': 'multipart/form-data' }).then(res =>{
            return 'ok'
    }).catch(error =>{
        // console.log('error.response', error.response);
            return 'error'
    })
}
export const editUser = async (input, id) => {
    // console.log('input, id', input, id)
    return await api.put('/users/'+id, input, { withCredentials: true },{ 'Content-Type': 'multipart/form-data' }).then(res =>{
        // console.log('res.response', res.response)
            return 1
    }).catch(error =>{
        // console.log('error.response', error.response);
            return 2
    })
}
export const checkEventoName = async (input) => {
    return await api.get(`/eventos/${input}`, { withCredentials: true }).then(res =>{
            // console.log('res', res)
            return false
    }).catch(error =>{
        // console.log('error.response', error.response);
            return true
    })
}
export const closeSesion = async () => {
    return await api.post('/logout', '', { withCredentials: true }).then(res =>{
        // console.log('res', res.data)
            return true
    }).catch(error =>{
        // console.log('error.response', error.response)
            return false
    })
}
export const ProductorEvents = async () =>{
    return await api.get('/productors/eventos', { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        console.log('error.response', error)
    })
}
export const getProductorTotalEvent = async (id) =>{
    return await api.get(`/productors/dashtotal/${id}`, { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        console.log('error.response', error)
    })
}
export const getProductorTotalTickets = async (id) =>{
    return await api.get(`/productors/tickets/${id}`, { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        console.log('error.response', error)
    })
}
export const getProductorPagina = async (id) =>{
    return await api.get(`/productors/compras/paginacion?${id}`, { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        console.log('error.response', error)
    })
}
export const getProductorDescargar = async (id) =>{
    return await api.get(`/productors/descargar/${id}`, { withCredentials: true }).then(res =>{
        return res.data
    }).catch(error =>{
        console.log('error.response', error)
    })
}
export const addCortesia = async (input, id) => {
    return await api.post('/cortesias/'+id, input, { withCredentials: true }).then(res =>{
        console.log('add', res)
            return res
    }).catch(error =>{
            return error
    })
}
export const listaCortesia = async (params) => {
    return await api.get('/cortesias/lista?'+params, { withCredentials: true }).then(res =>{
            return res
    }).catch(error =>{
            return error
    })
}
