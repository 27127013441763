import React from 'react';
import { Col, Container } from 'react-bootstrap';

const Terminos = () => {
  return (  
  <>
  <Container>
    <Col>
      <h2 className="mt-4 font-weight-bold">Proximos Eventos</h2>
    </Col>
  </Container>
</>
)
};

export default Terminos;
