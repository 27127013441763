import React from 'react'
import { Container, Spinner } from 'react-bootstrap'

export const CargandoBox = () => {
  return (
    <Container className=' d-flex h-100'>
      <div className='d-flex align-self-center mx-auto h-100'>
          <Spinner animation="border" role="status" size="lg" variant="primary" className="" />
      </div>
    </Container>
  )
}
