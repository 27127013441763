import React from 'react'
import { Container } from 'react-bootstrap'
import { Events } from 'components/acount/productor/events'
import { QueryClient, QueryClientProvider} from '@tanstack/react-query'

const queryClient = new QueryClient()

const PaginaProductor = () => {

    return (
    <QueryClientProvider client={queryClient}>
        <Container>
            <h2 className='font-weight-bold'>Tús eventos</h2>
            <Events />
        </Container>
    </QueryClientProvider>
  )
}

export default PaginaProductor