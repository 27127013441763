import { getVentaProductor } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
// import IsAuthTicketsMap from './IsAuthTcketsMap';
import IsAuthTicketsByComprasMap from './IsAuthTcketsByComprasMap';


const IsAuthListTickets = (props) => {
    const id  = props.id
    // console.log('list events', props)
    const [lista, setlista] = useState(null)

    useEffect(() => {
      let ignore = false
      if(!ignore){
        const tomardatos =async()=>{
          let datos = await getVentaProductor(id)
            setlista(datos)
            console.log('datos', datos)
        }
        tomardatos()
      }
      return ()=>{ignore = true}
    }, [id])

    const ticketVendidos = () =>{
      if(lista.length > 0){
        let data = lista.map(res => res.tickets.map(item => item.cantidad).reduce((prev, next) => prev + next))
        return data.reduce((prev, next) => prev + next)
      }
    }
    const punto = (input) => {
      if(input === 0 || input === null || input === undefined){
        return 0
      }
      return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    if(lista === null){
      return(
        <Col md={12} className='bg-white rounded p-5' >
          <CargandoBox />
        </Col>
       
      )
    }

    if(lista.length > 0){
      return (
        <Col md={12} className='bg-white rounded p-5' >
          <Col className='mb-5'>
            <Row className='justify-content-center'>
              <Col md={4} className='mx-auto rounded border bg-info p-4 text-center'>
                <h4 className='text-white-50'>Total de ventas  &nbsp;</h4>
                <span className='text-white h2'>${punto(lista.length >= 0 ? lista.map(item => item.tickets.map(res => res.cantidad * res.valor).reduce((prev, next) => prev + next)).reduce((prev, next) => prev + next) : 0)}</span>
              </Col>
              <Col md={4} className='mx-auto rounded border bg-danger p-4 text-center'>
                <h4 className='text-white'>Ticket vendidos &nbsp;</h4>
                <span className='text-white h2'>{ticketVendidos()}</span>
              </Col>
              <Col md={4} className='mx-auto rounded border bg-warning p-4 text-center'>
                <h4 className=' text-white'>Numero de ventas &nbsp;</h4>
                <span className='text-white h2'>{lista.length}</span>
              </Col>
            </Row>
          </Col>
          <h3>Detalle de Tickets</h3>
          <IsAuthTicketsByComprasMap list={lista} tickets={props.tickets} />
          {/* <h3>Detalle de Tickets</h3>
          <IsAuthTicketsMap list={lista} tickets={props.tickets} /> */}
          
        </Col>
      )
    }

      return(
      <Col className='bg-white rounded p-5 text-center'>
        <h2>no hay ventas todabia</h2>
      </Col>
      )
      
}

export default IsAuthListTickets