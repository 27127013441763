import { closeSesion } from 'components/api/service'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoBag, IoCreate, IoExit, IoNewspaper, IoPersonCircle, IoTicket, IoLogoAndroid } from 'react-icons/io5'

const Acount = ({...props}) => {
    const authUser = props.auth.user
    const cerrarSesion = async() =>{
        let data = await closeSesion()
        if(data){
            window.location.assign(process.env.REACT_APP_APP)
        }else{

        }
        // console.log('data', data)
    }
if(authUser.productor){
    return(
        <Container>
                <h2>Cuenta de <strong className='text-primary'>Productor</strong></h2>
            <Row>
                <Col md='3'><a href="/cuenta/crear-evento" className='bg-white p-5 text-center d-block rounded mb-4'><IoCreate size={60} /><br /> Crear Evento</a></Col>
                {/* <Col md='3'><a href="/cuenta/eventos" className='bg-white p-5 text-center d-block rounded mb-4'><IoNewspaper size={60} /><br /> Tus Eventos</a></Col> */}
                <Col md='3'><a href="/cuenta/productor" className='bg-white p-5 text-center d-block rounded mb-4'><IoNewspaper size={60} /><br /> Tus eventos</a></Col>
                {/* <Col md='3'><a href="/cuenta/eventos/ventas" className='bg-white p-5 text-center d-block rounded mb-4'><IoTicket size={60} /><br /> Tus Ventas</a></Col> */}
                {/* <Col md='3'><a href="/" className='bg-white p-5 text-center d-block rounded mb-4'><IoQrCodeSharp size={60} /><br /> Tu Validador</a></Col> */}
                <Col md='3'><a href="/docs/ticketone-V2.1.apk" className='bg-white p-5 text-center d-block rounded mb-4'><IoLogoAndroid size={60} /><br /> Descargar app</a></Col>
                <Col md='3'><a href="/cuenta/perfil" className='bg-white p-5 text-center d-block rounded mb-4'><IoPersonCircle size={60} /><br /> Tu Perfil</a></Col>
                <Col md='3'><a href="/cuenta/compras" className='bg-white p-5 text-center d-block rounded mb-4'><IoBag size={60} className='img mx-center' /><br /><span>Tus Compras</span></a></Col>
                <Col md='3'><span onClick={cerrarSesion} className='btn bg-white p-5 text-center d-block rounded mb-4'><IoExit size={60} className='img mx-center' /><br /><span>Cerrar Sesión</span></span></Col>
            </Row>
        </Container>
    )
}
  return (
    <Container>
        <h2>Tú Cuenta</h2>
        <Row>
                <Col md='3'><a href="/cuenta/perfil" className='bg-white p-5 text-center d-block rounded mb-4'><IoPersonCircle size={60} /><br /> Tu Perfil</a></Col>
                <Col md='3'><a href="/cuenta/compras" className='bg-white p-5 text-center d-block rounded mb-4'><IoBag size={60} /><br /><span>Tus Compras</span></a></Col>
                <Col md='3'><span onClick={cerrarSesion} className='btn bg-white p-5 text-center d-block rounded mb-4'><IoExit size={60} /><br /><span>Cerrar Sesión</span></span></Col>
            </Row>
    </Container>
  )
}

export default Acount