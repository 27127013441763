import { getProductorTotalEvent } from 'components/api/service'
import { CargandoBox } from 'components/general/cargandoBox'
import moment from 'moment'
import React, { useState } from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
// import { IoCash, IoCreate } from 'react-icons/io5'
import { IoStar } from 'react-icons/io5'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { CurrentTickets } from './currentTickets'
import { Dashboard } from './dashboard'
// import { Links } from './links'
import { TituloExpand } from './tituloExpand'

export const ItemEvent = (props) => {
    let history = useHistory();
    const [dashcache, setDashcache] = useState(false)
    const [dashresponse, setDashresponse] = useState([])
    

    const dashClick = async (input) => {
        if(!dashcache){
           await getProductorTotalEvent(input).then(res => {
                setDashresponse(res)
                setDashcache(true)
            })
        }
    }
    const goTo = (input) =>{
        history.push('/cuenta/venta-detalle', input)
    }
    const goToCortesia = (input) =>{
        history.push('/cuenta/cortesia', input)
    }
    const goToEdit = (input) =>{
        history.push('/cuenta/editar-evento', input)
    }
   
    const vencido = new Date(props.evento.inicio) >= new Date()
    

  return (
    <div className={`mb-2 rounded ${vencido ? 'bg-white ': 'bg-gray'}`}>
        <Accordion.Toggle as={Row} eventKey={props.evento.id} className='p-2' onClick={() =>{dashClick(props.evento.id)}}>
            <Col xs={4} md={1} className='relative text-center my-auto'>
                <img src={process.env.REACT_APP_API + props.evento?.imagen?.formats?.thumbnail.url} alt={props.evento.titulo} className='img-fluid' />
                <Badge variant='' className='text-black-50 d-block'>Ident: {props.evento.id}</Badge>
            </Col>
            <Col xs={6} md={5}>
                {props.evento.destacado ? <Badge variant='primary'><IoStar /> Destacado</Badge> : <Badge variant='secondary' className='text-black-50'>Normal</Badge>}
                <h2 className='h4 mb-0'>{props.evento.titulo}</h2>
                {/* <h3 className='mb-0'>Crossover Attack!: Gordom - Tiempos Duros - Monjas con Atraso - Matahero - Dfacto</h3> */}
                <span className='text-gray'>
                {new Date(props.evento.inicio) >= new Date() ? '': 'Terminado '}
                {moment(props.evento.inicio, "YYYYMMDD").endOf('day').fromNow()}{' '}
            </span>
            </Col>
            <Col md={2} className='text-right my-auto'>
                <Badge pill className=' text-black-50' variant={props.evento.publico ? "success" : "warning"}>{props.evento.publico ? 'evento publicado': 'evento en espera'}</Badge>{''}
                <Badge pill variant="light">{props.evento.mayor_edad ? 'evento +18': 'publico general'}</Badge>{' '}
                {props.evento.privado ? <Badge pill variant="info">evento privado</Badge>: ''}
                {/* <Badge variant="secondary">Secondary</Badge>{' '}
                <Badge variant="success">Success</Badge>{' '}
                <Badge variant="danger">Danger</Badge>{' '}
                <Badge variant="warning">Warning</Badge>
                <Badge variant="dark">Dark</Badge> */}
            </Col>
            <Col xs={6} md={2} className='text-center my-auto'>
                <div className='bg-gray rounded py-1'>
                    <span className='d-block font-weight-bold'>Fecha del evento</span>
                    <span className='font-smaller'>{moment(props.evento.inicio).format('DD/MM/YY H:mm')}</span>
                </div>
            </Col>
            <Col sm={6} md={2} className='text-center text-md-center my-auto'>
                    <Button block variant='primary' size='sm' onClick={(e)=>{e.stopPropagation();e.preventDefault(); goTo(props.evento)}}  className='font-weight-bold'>
                        Detalle de ventas
                        {/* <IoCash size={18} className='ml-2' /> */}
                    </Button>
                    <Button block variant='light' size='sm' onClick={(e)=>{e.stopPropagation();e.preventDefault();goToEdit(props.evento)}} className='font-weight-bold'>
                        Editar evento
                        {/* <IoCreate size={18} className='ml-2 align-middle' /> */}
                    </Button>
                    <Button block variant='warning' size='sm' onClick={(e)=>{e.stopPropagation();e.preventDefault();goToCortesia(props.evento)}} className='font-weight-bold'>
                        Cortesia
                        {/* <IoCreate size={18} className='ml-2 align-middle' /> */}
                    </Button>
                    {/* <IoCreate size={35} className='text-warning' /> */}
                    {/* <IoCaretDownCircleSharp size={35} className='text-gray' /> */}
            </Col>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={props.evento.id} className='border-top'>
            <div className='p-3'>
                {dashcache ? <Dashboard props={dashresponse} evento={props.evento} /> : <CargandoBox />}
                <TituloExpand titulo='Tickets disponibles actuales' />
                <CurrentTickets props={props.evento.tickets} bg='#eaeaea'/>
                {/* <TituloExpand titulo='Utilidades' /> */}
            </div>
        </Accordion.Collapse>
    </div>
  )
}
